import { ConditionType, getDatePickerFromQueryObject, getStringConditionFromDatePicker } from '../components/filters/Utils';
import { Filter } from '../types/filter.types';
import { SortDir } from '../views/CustomDashboards/types';
import { FieldType, FieldTypeEnum, typeLabelMap } from '../views/Sources/Mapping/mapping.types';
import { extractKeyValues, isDeepEqual, severityWithNone } from './Utils';

export const filterToExpression = (typeMap, filterObj, isNewDynamicRange) => {
  const operands = Object.keys(filterObj).map(fieldName => {
    const condition = decodeURIComponent(filterObj[fieldName]);
    if (FieldTypeEnum[typeMap[fieldName]] === FieldType.Boolean) {
      return filterObj[fieldName].length
        ? createBooleanExpressionObj(fieldName, filterObj[fieldName], typeMap, isNewDynamicRange)
        : { expression: { fieldName, value: {} } };
    }
    if (filterObj[fieldName][0] !== null && !condition) {
      return { expression: { fieldName } };
    }

    if (condition === filterObj[fieldName].toString()) {
      return createExpressionObj(fieldName, { operator: 'in', value: { values: filterObj[fieldName] } }, typeMap, isNewDynamicRange);
    }
    const conditionObj = condition && JSON.parse(condition);
    const operator = condition && Object.keys(conditionObj)[0];
    return !operator
      ? createExpressionObj(fieldName, { operator: 'equals', value: conditionObj }, typeMap, isNewDynamicRange)
      : !['and', 'or'].includes(operator)
        ? createExpressionObj(fieldName, conditionObj, typeMap, isNewDynamicRange)
        : {
            [operator]: {
              operands: conditionObj[operator].map(operand => createExpressionObj(fieldName, operand, typeMap, isNewDynamicRange)),
            },
          };
  });
  return operands.length ? { and: { operands } } : {};
};
export const updateFilterInExpression = (typeMap, newFilter, filterExp, isNewDynamicRange: boolean) =>
  filterToExpression(
    typeMap,
    {
      ...expressionToFilter(typeMap, filterExp, isNewDynamicRange),
      ...newFilter,
    },
    isNewDynamicRange
  );
export const expressionToFilter = (typeMap, filterExp, isNewDynamicRange: boolean) =>
  filterExp.expression
    ? createFilterObj(typeMap, {}, filterExp, isNewDynamicRange)
    : filterExp.and?.operands.reduce((obj, exp) => createFilterObj(typeMap, obj, exp, isNewDynamicRange), {}) || {};

export const createExpressionObj = (fieldName, conditionObj, typeMap, isNewDynamicRange: boolean) => ({
  expression: {
    fieldName,
    ...getExpressionCondition(conditionObj, typeLabelMap[typeMap[fieldName]], isNewDynamicRange),
  },
});

export const addCustomFiltersAsExpression = ({
  expressionFiltersObj,
  operand,
  operator,
  fields,
  value,
  fieldTypeMap,
  isNewDynamicRange,
}) => {
  if (!value) {
    return expressionFiltersObj;
  }
  const adddedFiltersObject = fields.reduce(
    (acc, field) => ({
      ...acc,
      [operand]: {
        operands: [...acc[operand].operands, createExpressionObj(field, { operator, value }, fieldTypeMap, isNewDynamicRange)],
      },
    }),
    { [operand]: { operands: [] } }
  );

  return { ...expressionFiltersObj, and: { operands: [...(expressionFiltersObj?.and?.operands || []), adddedFiltersObject] } };
};

const createBooleanExpressionObj = (fieldName, values, typeMap, isNewDynamicRange) => ({
  or: { operands: values.map(val => createExpressionObj(fieldName, val, typeMap, isNewDynamicRange)) },
});

const emptyCondition = { and: [{}] };

const createFilterObj = (typeMap, filterObj, filterExp: Filter, isNewDynamicRange: boolean) => {
  const { fieldName } =
    // @ts-ignore
    Object.values(filterExp)[0]?.expression || filterExp.expression || Object.values(filterExp)[0].operands[0].expression;
  const filterCondition = getFilterCondition(typeLabelMap[typeMap[fieldName]], filterExp, typeMap, isNewDynamicRange);
  return {
    ...filterObj,
    [fieldName]:
      !filterCondition || isDeepEqual(emptyCondition, filterCondition)
        ? []
        : Array.isArray(filterCondition.value)
          ? filterCondition.value
          : [encodeURIComponent(JSON.stringify(filterCondition))],
  };
};

export function getExpressionCondition(operand, type, isNewDynamicRange: boolean) {
  if (type === 'date') {
    return getStringConditionFromDatePicker(operand, isNewDynamicRange);
  }

  if (type === 'bool') {
    const hasValue = JSON.parse(operand) !== null;
    return {
      boolCondition: {
        [hasValue ? 'equals' : 'empty']: hasValue ? operand : {},
      },
    };
  }

  return {
    [ConditionType[type] || 'stringCondition']: {
      [operand.operator]: ['empty', 'notEmpty'].includes(operand.operator)
        ? {}
        : Array.isArray(operand.value?.values)
          ? { values: operand.value.values.filter(v => v) }
          : operand.value,
      ...(Array.isArray(operand.value?.values) && operand.value.values.includes(null) && { addNullFilter: true }),
    },
  };
}

function getFilterCondition(type, filterExp, typeMap, isNewDynamicRange) {
  if (type === 'date') {
    return getDatePickerFromQueryObject(filterExp, isNewDynamicRange);
  }

  if (type === 'bool') {
    if (!filterExp.expression?.boolCondition && !filterExp.or) {
      return { value: [] };
    }

    if (filterExp.expression) {
      const key = Object.keys(filterExp.expression.boolCondition)[0];
      return {
        value: key === 'notEmpty' ? [true, false] : [key === 'equals' ? Object.values(filterExp.expression.boolCondition)[0] : null],
      };
    }
    return {
      value: filterExp.or.operands
        .map(({ expression }) => expression.boolCondition)
        .map(boolCondition => (Object.keys(boolCondition)[0] === 'equals' ? Object.values(boolCondition)[0] : null)),
    };
  }

  const typeCondition = ConditionType[type] || 'stringCondition';

  const getFilterObj = ({ expression }) =>
    expression[typeCondition]
      ? Array.isArray(expression[typeCondition].in?.values)
        ? {
            operator: 'in',
            value: [...expression[typeCondition].in.values.filter(v => v), ...(expression[typeCondition].addNullFilter ? [null] : [])],
          }
        : {
            operator: Object.keys(expression[typeCondition])[0],
            value:
              type === 'number'
                ? parseInt(Object.values(expression[typeCondition])[0] as string)
                : Object.values(expression[typeCondition])[0],
          }
      : {};

  const operator = Object.keys(filterExp)[0];
  if (operator === 'not') {
    return getFilterCondition(type, filterExp.not, typeMap, isNewDynamicRange);
  }
  if (operator === 'expression') {
    if (filterExp.expression[typeCondition] && Array.isArray(filterExp.expression[typeCondition].in?.values)) {
      return getFilterObj(filterExp);
    }
    return { and: [getFilterObj(filterExp)] };
  }
  return {
    [operator]: filterExp[operator].operands.map(v => (v.expression ? getFilterObj(v) : expressionToFilter(typeMap, v, isNewDynamicRange))),
  };
}

const operandOrAndFilter = (filter: Filter, inclusive = false) => {
  const operands = ['or', 'and'];
  return (
    (inclusive
      ? filter.and?.operands.filter(op => operands.includes(Object.keys(op)[0]))
      : filter.and?.operands.filter(op => !operands.includes(Object.keys(op)[0]))) || []
  );
};
export const cleanEmptyFilters = (filter: Filter | undefined): Filter | undefined =>
  filter &&
  Object.keys(filter).length &&
  [
    ...extractKeyValues(operandOrAndFilter(filter), 'expression').filter(
      ({ fieldName, value, ...condition }) => Object.values(condition).length
    ),
    ...operandOrAndFilter(filter, true),
  ].length
    ? {
        ...filter,
        and: {
          ...filter.and,
          operands: [
            ...extractKeyValues(operandOrAndFilter(filter), 'expression')
              .filter(({ fieldName, value, ...condition }) => Object.values(condition).length)
              .map(val => ({ expression: val })),
            ...operandOrAndFilter(filter, true),
          ],
        },
      }
    : undefined;

const defaultSortBySeverityInput: { data: { [key: string]: string }[]; severityFieldName: string; key: string; direction?: SortDir } = {
  data: [],
  severityFieldName: '',
  key: 'value',
  direction: SortDir.DESC,
};

export const sortBySeverity = (defaultSortBySeverity = defaultSortBySeverityInput) => {
  if (defaultSortBySeverity.severityFieldName && checkIfSeverityFilter(defaultSortBySeverity.severityFieldName)) {
    return defaultSortBySeverity.data.toSorted(({ [defaultSortBySeverity.key]: a }, { [defaultSortBySeverity.key]: b }) =>
      defaultSortBySeverity.direction === SortDir.ASC
        ? severityWithNone.indexOf(b) - severityWithNone.indexOf(a)
        : severityWithNone.indexOf(a) - severityWithNone.indexOf(b)
    );
  }

  return defaultSortBySeverity.data;
};

export function checkIfSeverityFilter(severityFieldName: string) {
  return severityFieldName.split('.')[1] === 'severity';
}
