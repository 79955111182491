import { useContext, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { useTheme } from '@mui/material';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import { NotificationContext, WorkflowStatus, WorkflowType } from '../../context/AvSnackBarProvider';
import useHandleError from '../../hooks/useHandleError';
import { PresignedUrlType, usePresignedUrl } from '../../hooks/usePresignedURL';
import useQueryObject from '../../hooks/useQueryObjectSql';
import { FeatureFlags } from '../../types';
import { Filter } from '../../types/filter.types';
import { checkIfSeverityFilter, sortBySeverity } from '../../utils/filterUtils';
import { getNetworkErrorCode, noop } from '../../utils/Utils';
import { useBuildQueryParams, useMeasurementsList } from '../Reports/hooks';
import { FileFormat, MeasurementCategory, MeasurementUnit, TimeBucket, TimeBucketName } from '../Reports/types';
import { FieldType, FieldTypeEnum, FieldType as MappingFieldType } from '../Sources/Mapping/mapping.types';
import {
  AllTypeWidgets,
  CustomDashboardDto,
  DashboardExportDto,
  DashboardReportPermission,
  dwQueryDefault,
  PermissionType,
  ReportsTypes,
  WidgetCategory,
} from './types';
import { canRenderWidget, defaultDeliveryConfig, removeTempIdsFromDashboard, shouldShowTotals } from './Utils';

const getOverrideSortedData = (data: Record<string, string>[], widget) => {
  const severitySortField = widget.requests[0].select.dims.find(({ name }) => checkIfSeverityFilter(name));
  return sortBySeverity({
    data,
    severityFieldName: severitySortField?.name,
    key: severitySortField?.name,
    direction: widget.requests[0].sorting[0]?.dir,
  });
};

export const useGetWidgetData = ({
  widget,
  contextFilter,
  visible = true,
  debounceQueries = false,
}: {
  widget: AllTypeWidgets;
  contextFilter?: Filter;
  visible?: boolean;
  debounceQueries?: boolean;
}) => {
  const projectionName = widget.requests?.[0]?.projectionId.name;
  const {
    featureFlags,
    accountEntities: { aggProjs },
  } = useAvContext();
  const activeEntityId = aggProjs[projectionName]?.entityTypeId.name;

  const showTotals = shouldShowTotals(widget);
  const canRender = canRenderWidget({ widget });
  const enabled = visible && !!widget.requests.length && canRender;
  const { data: measurements = [] } = useMeasurementsList({
    entityName: activeEntityId,
    filterFunc: v => v.context.includes(activeEntityId),
  });
  const fullMeasurements = useBuildQueryParams({
    headCellsIds: measurements.map(({ systemName }) => systemName),
    measurements,
  });

  const finalWidget = {
    ...widget,
    requests: widget.requests.map(r => ({
      ...r,
      select: {
        ...r.select,
        metrics: r.select.metrics.map(m => ({
          ...m,
          name: fullMeasurements.find(fullMeasurement => fullMeasurement.alias === m.alias)?.systemName || m.name,
        })),
      },
    })),
  };

  const {
    totals,
    data = [],
    isLoading,
    isRefetching,
    errors,
    totalRowCount,
  } = useQueryObject({
    queryObject: finalWidget.requests,
    showTotals,
    contextFilter: featureFlags[FeatureFlags.DashboardGlobalFilters] ? contextFilter : undefined,
    totalRowCount: widget.category === WidgetCategory.Table,
    debounceQueries,
    options: { enabled },
  });

  return {
    data: checkIfSeverityFilter(widget.requests[0].sorting[0]?.name || '') ? getOverrideSortedData(data, widget) : data,
    totals,
    isLoading,
    isRefetching,
    errors,
    totalRowCount,
  };
};

export const useGetClickthroughWidgetData = ({ queryObject, contextFilter, enabled = true }) => {
  const { featureFlags } = useAvContext();
  const {
    totals,
    data = [],
    isLoading,
    isRefetching,
    totalRowCount = 0,
  } = useQueryObject({
    queryObject,
    showTotals: false,
    contextFilter: featureFlags[FeatureFlags.DashboardGlobalFilters] ? contextFilter : undefined,
    totalRowCount: true,
    options: {
      placeholderData: keepPreviousData,
      enabled,
    },
  });

  return { data, totals, totalRowCount, isLoading, isRefetching };
};

export const useGetFieldType = activeProjName => {
  const { fieldTypeMap, aggProjs } = useAvContext().accountEntities;
  const { name: entityName } = aggProjs[activeProjName].entityTypeId;
  const { data: measurements = [] } = useMeasurementsList({
    entityName,
    filterFunc: v => v.context.includes(entityName),
  });

  const isNumber = (entityFieldKey, measurement) =>
    FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.Number ||
    measurement?.category === MeasurementCategory.NUMERIC ||
    measurement?.category === MeasurementCategory.POPULATION_COUNT;

  const isPercentage = measurement =>
    measurement?.category === MeasurementCategory.PERCENTAGE ||
    (measurement?.category === MeasurementCategory.MATH_EXPRESSION && measurement?.unit.name === MeasurementUnit.Percentage);

  const isDate = entityFieldKey => FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.Date;

  const isHistoricDate = entityFieldKey => TimeBucket[entityFieldKey];

  return ({ entityFieldKey }) => {
    const maybeMeasurement = measurements.find(({ systemName }) => systemName === entityFieldKey);
    return isNumber(entityFieldKey, maybeMeasurement)
      ? 'number'
      : isDate(entityFieldKey)
        ? 'date'
        : isHistoricDate(entityFieldKey)
          ? 'historic-date'
          : isPercentage(maybeMeasurement)
            ? 'percentage'
            : Array.isArray(fieldTypeMap[entityFieldKey])
              ? 'array'
              : FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.Message
                ? 'json'
                : FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.Boolean
                  ? 'bool'
                  : FieldTypeEnum[fieldTypeMap[entityFieldKey]] === MappingFieldType.FIX
                    ? FieldType.FIX
                    : undefined;
  };
};
export const useDashboardsList = (type: ReportsTypes) => {
  const { api } = useAvContext();

  return useQuery<CustomDashboardDto[]>({
    queryKey: ['customDashboardsList', type],
    queryFn: () =>
      api(GET_CUSTOM_DASHBOARDS, {
        options: {
          type,
          excludePredefined: false,
        },
      }).then(({ data }) => data.findDashboardReports),
    gcTime: 0,
  });
};
export const useDashboard = ({ id, onSuccess = v => v, enabled = true }) => {
  const { api } = useAvContext();
  return useQuery<any>({
    queryKey: ['findDashboardById', id],
    queryFn: () =>
      api(GET_DASHBOARD, {
        options: { id },
        onSuccess: ({ data, errors }) =>
          !errors &&
          onSuccess({
            ...data.findDashboardReportById,
            widgets: data.findDashboardReportById.widgets.map(widget => ({
              ...widget,
              drillDownHierarchy: { ...widget.drillDownHierarchy, activeIndex: 0 },
              requests: (widget.category === WidgetCategory.Text ? [dwQueryDefault] : widget.requests).map(request => ({
                ...request,
                select: {
                  ...request.select,
                  dims: request.select.dims || [],
                  metrics: request.select.metrics || [],
                },
              })),
            })),
          }),
      }),
    enabled: !!id && enabled,
    gcTime: 0,
  });
};

export const useEditDashboardDetails = ({ id, onSuccess }) => {
  const { mutate: save } = useEditDashboards({ onSuccess });
  const { data: initDashboard } = useDashboard({
    id,
  });
  return dashboard => save({ customDashboard: { ...initDashboard, ...dashboard }, isCreate: false });
};

function mapWidgetsDrillDown(widgets: AllTypeWidgets[]) {
  return widgets.map(({ drillDownHierarchy, requests, ...widget }) => ({
    ...widget,
    drillDownHierarchy: { fields: drillDownHierarchy?.fields },
    requests:
      widget.category === WidgetCategory.Text
        ? []
        : requests.map(r => ({ ...r, ...(r.top ? { top: { ...r.top, offset: 0 } } : undefined) })),
  }));
}

export const useEditDashboards = ({
  onSuccess,
}: {
  onSuccess?: (val: boolean, id: string, shouldGoBack: boolean, isCreate: boolean) => void;
}) => {
  const { api, featureFlags } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: reRun } = useReRunDashboard(noop);
  const handleError = useHandleError(featureFlags[FeatureFlags.ShowServerErrorInToast]);

  return useMutation({
    mutationFn: ({
      isRerun = false,
      isSaveAsNew = false,
      isCreate = false,
      shouldGoBack = true,
      hasNewName = false,
      customDashboard,
    }: {
      isRerun?: boolean;
      isSaveAsNew?: boolean;
      isCreate?: boolean;
      shouldGoBack?: boolean;
      hasNewName?: boolean;
      customDashboard: CustomDashboardDto;
    }) => {
      const {
        isIntegrationActive,
        predefined,
        sharedUsers,
        createdByUserId,
        updatedByUserId,
        updatedAt,
        lastRunAt,
        widgets,
        ...newCustomDashboard
      } = {
        ...customDashboard,
        id: isSaveAsNew ? undefined : customDashboard.id,
        name: isSaveAsNew && !hasNewName ? `${customDashboard.name} (new)` : customDashboard.name,
      };
      const newWidgets: any = mapWidgetsDrillDown(widgets);
      return api(!isCreate && !isSaveAsNew ? UPDATE_DASHBOARD : CREATE_DASHBOARD, {
        options: {
          dashboardReportDto: removeTempIdsFromDashboard({
            ...newCustomDashboard,
            widgets: newWidgets,
            ...(newCustomDashboard.deliveryConfig
              ? {}
              : {
                  deliveryConfig: defaultDeliveryConfig(
                    newCustomDashboard.type === ReportsTypes.Visual ? FileFormat.PDF_FORMAT : FileFormat.CSV_FORMAT
                  ),
                }),
          }),
        },
        onError: err => onErrorSnackBar(enqueueSnackbar, err),
      })
        .then(async data => {
          const id = data?.data?.[!isCreate && !isSaveAsNew ? 'updateUserDashboardReport' : 'createUserDashboardReport']?.id;
          if (isRerun) {
            await reRun(id);
          }
          return id;
        })
        .then(id => onSuccess?.(isRerun, id, shouldGoBack, isCreate))
        .catch(e => {
          if (getNetworkErrorCode(e) === 400) {
            console.warn(e);
            enqueueSnackbar(e.message, { variant: 'error' });
          } else {
            handleError(e);
          }
        });
    },
  });
};

export const useEditSharedDashboardUsers = ({ onSuccess = d => d }: { onSuccess?: (data) => void }) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({
      id,
      sharedUsers = {
        editors: [],
        viewers: [],
        type: PermissionType.private,
      },
    }: {
      id: string;
      sharedUsers: DashboardReportPermission;
    }) =>
      api(UPDATE_DASHBOARD_SHARED_USERS, {
        options: { sharedUsers, id },
        onError: err => onErrorSnackBar(enqueueSnackbar, err),
      })
        .then(data => data?.data?.updateUserDashboardReportSharedUsers)
        .then(onSuccess),
  });
};

export const useReRunDashboard = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (id: string) =>
      api(RERUN_DASHBOARD, { options: { id }, onError: err => onErrorSnackBar(enqueueSnackbar, err) }).then(onSuccess),
  });
};

export const useDeleteDashboards = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: (ids: string[]) =>
      api(DELETE_DASHBOARDS, {
        options: { ids },
        onError: err => onErrorSnackBar(enqueueSnackbar, err),
      }).then(onSuccess),
  });
};

const onErrorSnackBar = (enqueueSnackbar, err) => enqueueSnackbar(`${err.message}`, { variant: 'error' });

export const useDuplicateDashboard = onSuccess => {
  const { api } = useAvContext();
  return useMutation({ mutationFn: (id: string) => api(DUPLICATE_DASHBOARD, { options: { id } }), onSuccess });
};

export const useEditDashboardSchedule = (onSuccess: () => void) => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (dashboardScheduleDto: any) => api(EDIT_REPORT_SCHEDULE, { options: { dashboardScheduleDto } }),
    onSuccess: () => {
      enqueueSnackbar('Successfully saved scheduling', {
        variant: 'success',
      });
      onSuccess();
    },
    onError: err => onErrorSnackBar(enqueueSnackbar, err),
  });
};
export const useGetDisplayName = (activeProjName: string) => {
  const { aggProjs, fieldMap } = useAvContext().accountEntities;
  const { data: measurements = [] } = useMeasurementsList({
    entityName: aggProjs[activeProjName].entityTypeId.name,
    filterFunc: v => v.context.includes(aggProjs[activeProjName].entityTypeId.name),
  });
  return fieldName =>
    fieldMap[fieldName]?.title || measurements.find(v => v.systemName === fieldName)?.displayName || TimeBucketName[fieldName];
};

export function useExportDashboard({ dashboardExportDto }: { dashboardExportDto: DashboardExportDto }) {
  const [isWorking, setIsWorking] = useState<number>();
  const { api } = useAvContext();
  const { mutate: getDashboardPresignedUrl } = usePresignedUrl(PresignedUrlType.QUERY);

  const { setRunInfo } = useContext(NotificationContext);
  const result = useQuery<any>({
    queryKey: ['exportDashboard', isWorking],
    queryFn: () =>
      api(EXPORT_PAGE, {
        options: {
          dashboardExportDto,
        },
        onSuccess: ({ data }) => {
          setRunInfo({
            ...data.triggerDashboardExport,
            returnStatusOnly: false,
            workflowType: WorkflowType.ExportDashboard,
            onSuccess: ({ status }) => {
              if (status === WorkflowStatus.COMPLETED) {
                getDashboardPresignedUrl({ wfId: data.triggerDashboardExport.wfId, runId: data.triggerDashboardExport.runId });
              }
            },
          });
        },
      }),
    gcTime: 0,
    enabled: !!isWorking,
  });

  return { exportDashboard: () => setIsWorking(Date.now()), isLoading: result.isLoading };
}

export const useActivateDashboards = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ ids, active }: { ids: string[]; active: boolean }) =>
      api(ACTIVATE_REPORTS, {
        options: {
          ids,
          active,
        },
        onError: err => onErrorSnackBar(enqueueSnackbar, err),
      }),
    onSuccess,
  });
};
export const useAddAppDashboards = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: ({ ids, app }: { ids: string[]; app: string }) =>
      api(ADD_APP_REPORTS, {
        options: {
          ids,
          app,
        },
        onError: err => onErrorSnackBar(enqueueSnackbar, err),
      }),
    onSuccess,
  });
};

const getCustomPalette = (colors: string[], dataLength: number = 0): string[] =>
  dataLength < colors.length ? colors : [...colors, ...getCustomPalette(colors, dataLength - colors.length)];

export const useCustomPalette = data => {
  const {
    palette: { chartColors: defaultColors },
  } = useTheme();

  return useMemo(() => getCustomPalette(defaultColors, data?.length), [data?.length]);
};

export const useSharableReportUsers = (enabled = true) => {
  const { api } = useAvContext();

  return useQuery({
    queryKey: ['getShareableUsers'],
    queryFn: () =>
      api(GET_SHAREABLE_USERS_LIST, {
        onSuccess: ({ data, errors: e }) => {
          if (!e) {
            return (data.getShareableUsers || []).map(row => ({
              ...row,
              fullName: `${row.firstName} ${row.lastName}`,
            }));
          }
          return [];
        },
      }),
    options: { enabled },
  } as any);
};

const GET_SHAREABLE_USERS_LIST = gql`
  query {
    getShareableUsers {
      id
      firstName
      lastName
    }
  }
`;

export const RERUN_DASHBOARD = gql`
  mutation ($id: String!) {
    processDashboardReport(id: $id) {
      workflowRun {
        id
        runId
      }
    }
  }
`;
export const DUPLICATE_DASHBOARD = gql`
  mutation ($id: String!) {
    duplicateDashboardReport(id: $id) {
      name
    }
  }
`;

export const CREATE_DASHBOARD = gql`
  mutation ($dashboardReportDto: DashboardReportInput!) {
    createUserDashboardReport(dashboardReportDto: $dashboardReportDto) {
      id
    }
  }
`;
export const UPDATE_DASHBOARD = gql`
  mutation ($dashboardReportDto: DashboardReportInput!) {
    updateUserDashboardReport(dashboardReportDto: $dashboardReportDto) {
      id
    }
  }
`;

export const UPDATE_DASHBOARD_SHARED_USERS = gql`
  mutation ($sharedUsers: DashboardReportPermissionInput!, $id: String!) {
    updateUserDashboardReportSharedUsers(sharedUsers: $sharedUsers, id: $id) {
      id
      dashboardReportPermission {
        type
        editors
        viewers
      }
    }
  }
`;

const GET_CUSTOM_DASHBOARDS = gql`
  query ($type: String!, $excludePredefined: Boolean) {
    findDashboardReports(type: $type, excludePredefined: $excludePredefined) {
      id
      name
      description
      type
      accountId
      active
      intervalExpression
      deliveryConfig
      lastRunAt
      apps
      createdByUserId
      updatedByUserId
      updatedAt
      predefined
      globalFilters
      dashboardReportPermission {
        type
        editors
        viewers
      }
      widgets {
        id
        type
        category
        drillDownHierarchy {
          fields {
            name
            alias
          }
        }
        requests {
          filter
          groupBy
          distinct
          source
          metricsFilterThreshold
          select {
            metrics {
              name
              alias
            }
            dims {
              name
              alias
            }
          }
          sorting {
            name
            dir
          }
          top {
            size
            groupOthers
            offset
          }
          projectionId {
            name
            builtIn
          }
          timeRange
        }
        definition {
          title
          coords
          custom
        }
      }
      userPermissionType
    }
  }
`;

export const GET_DASHBOARD = gql`
  query ($id: String!) {
    findDashboardReportById(id: $id) {
      id
      name
      description
      type
      accountId
      active
      intervalExpression
      deliveryConfig
      apps
      predefined
      globalFilters
      dashboardReportPermission {
        type
        editors
        viewers
      }
      userPermissionType
      widgets {
        id
        type
        category
        drillDownHierarchy {
          fields {
            name
            alias
          }
        }
        requests {
          filter
          groupBy
          distinct
          source
          metricsFilterThreshold
          select {
            metrics {
              name
              alias
            }
            dims {
              name
              alias
            }
          }
          sorting {
            name
            dir
          }
          top {
            size
            groupOthers
            offset
          }
          projectionId {
            name
            builtIn
          }
          timeRange
        }
        definition {
          title
          coords
          custom
        }
      }
    }
  }
`;

export const DELETE_DASHBOARDS = gql`
  mutation ($ids: [String]!) {
    deleteUserDashboardReport(ids: $ids)
  }
`;

export const EXPORT_PAGE = gql`
  query TriggerDashboardExport($dashboardExportDto: TriggerDashboardExportInput!) {
    triggerDashboardExport(dashboardExportDto: $dashboardExportDto) {
      runId
      wfId
    }
  }
`;

export const ACTIVATE_REPORTS = gql`
  mutation SetReportStatus($ids: [String!]!, $active: Boolean!) {
    setDashboardReportStatus(ids: $ids, active: $active)
  }
`;

export const ADD_APP_REPORTS = gql`
  mutation addDashboardReportApp($ids: [String!]!, $app: String!) {
    addDashboardReportApp(ids: $ids, app: $app)
  }
`;

const EDIT_REPORT_SCHEDULE = gql`
  mutation UpdateUserDashboardReportSchedule($dashboardScheduleDto: DashboardScheduleDto!) {
    updateUserDashboardReportSchedule(dashboardScheduleDto: $dashboardScheduleDto) {
      id
    }
  }
`;
