// eslint-disable-next-line import/prefer-default-export
export const FieldNameDescriptor = {
  '.google.protobuf.Struct': 'MESSAGE',
};

export const permissionArrayToTree = (permissions: string[]) =>
  [
    ...permissions,
    ...(permissions.includes('BACKOFFICE:UPDATE') ? ['BACKOFFICE:READ', 'BACKOFFICE:CREATE', 'BACKOFFICE:DELETE'] : []),
  ].reduce((acc, permission) => {
    const parts = permission.split(':');
    const [entity, action] = parts.slice(-2);

    return parts.length === 2
      ? { ...acc, [entity]: { ...(acc[entity] || {}), [action]: true } }
      : {
          ...acc,
          [parts[0]]: {
            ...(acc[parts[0]] || {}),
            [entity]: { ...(acc[parts[0]]?.[entity] || {}), [action]: true },
          },
        };
  }, {});
