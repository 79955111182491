import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags, PAGE_PATHS } from '../types';

const Entities = lazy(() => import('../views/Entities/Entities'));

const EntitiesRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
    featureFlags,
  } = useAvContext();
  const enableOrgEntityPane = featureFlags[FeatureFlags.ShowOrgEntityPane];
  const path = PAGE_PATHS.ENTITIES;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <Entities />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) && enableOrgEntityPane}>
            <Entities />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};
export default EntitiesRoute;
