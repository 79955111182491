import { datadogRum } from '@datadog/browser-rum';
import * as Auth from 'aws-amplify/auth';
import { useIdleTimer } from 'react-idle-timer';

export const shouldSkipUILogin = () => import.meta.env.VITE_SKIP_AUTH === 'true';
export const shouldNotLogoutOnAttributeChange = () => import.meta.env.VITE_ATTRIBUTES_CHANGE === 'true';

export const accessTokenByAccountId = 'accessTokenByAccountId';

// eslint-disable-next-line consistent-return
export const overrideAccountIdUserAttribute = async (accountId, { logout, loginWithRedirect }, attempts = 0) => {
  try {
    await Auth.updateUserAttributes({
      userAttributes: {
        'custom:web_account_id': accountId,
      },
    });
  } catch (error) {
    if (attempts < 1) {
      const { accessToken } = (await fetchSession({})).tokens ?? {};
      if (accessToken) {
        await overrideAccountIdUserAttribute(accountId, { logout, loginWithRedirect }, attempts + 1);
      } else {
        console.debug('no token for updating the attribute');
        return undefined;
      }
    } else {
      console.error(
        `[authentication error]: Error changing account ID user attribute to: ${accountId} after 2 attempts ${JSON.stringify(
          (error as any).message || error
        )}`
      );
      if (!shouldNotLogoutOnAttributeChange()) {
        logoutFromAvalor(logout);
      }
    }
  }
};

export const fetchSession = async ({ forceRefresh = true, firstLogin = false }: { forceRefresh?: boolean; firstLogin?: boolean }) => {
  try {
    const res = await Auth.fetchAuthSession({ forceRefresh });
    if (!res.tokens?.idToken && !firstLogin) {
      console.error(
        `[authentication error]: token is empty after refresh. response: ${JSON.stringify(res)}, forceRefresh: ${forceRefresh}`
      );
    }
    return res;
  } catch (error) {
    console.error(`[authentication error]: Fetch session error: ${(error as any).message} ${(error as any).underlyingError} `);
    return { error: (error as any).underlyingError, tokens: { accessToken: undefined, idToken: undefined } };
  }
};

export const logoutFromAvalor = ({ logout, loginWithRedirect, redirectBack }) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem(accessTokenByAccountId);
  localStorage.removeItem('accountId');
  localStorage.removeItem('passedLogin');

  if (redirectBack) {
    loginWithRedirect(window.location.pathname + window.location.search);
  } else {
    localStorage.removeItem('authConfig');
    logout();
  }
};

export const getAccountTokenFromLS = accountId => JSON.parse(localStorage.getItem(accessTokenByAccountId) || '{}')[accountId];

export const updateTokenInLS = ({ token, accountId }) => {
  const accessTokenByAccountIdValue = JSON.parse(localStorage.getItem(accessTokenByAccountId) || '{}');
  localStorage.setItem(accessTokenByAccountId, JSON.stringify({ ...accessTokenByAccountIdValue, [accountId]: token }));
};

export const useLogoutWhenUserIsIdle = ({ logout, featureFlag }) => {
  const timeout = getTimeoutFromAuthConfig({ key: 'logoutInactivityTimeout', defaultValue: 1440 }); // default is 24 hours
  const onIdle = () => {
    if (featureFlag) {
      logout();
    }
  };
  useIdleTimer({
    timeout,
    onIdle,
    startOnMount: true,
    crossTab: true,
    syncTimers: 100,
    name: 'logoutInActivity',
  });
};

export const useUserIsActive = ({ setIsNotIdle, accessTokenTimeout }) => {
  const timeout = accessTokenTimeout - 10000; // 10 seconds before the token expires
  useIdleTimer({
    timeout,
    onIdle: () => {
      setIsNotIdle(false);
    },
    onActive: () => {
      setIsNotIdle(true);
    },
  });
};

export const getAccessTokenTimeout = () => {
  const timeoutInSeconds = getTimeoutFromAuthConfig({ key: 'accessTokenTimeoutInMinutes', defaultValue: 5 }); // default is 5 minutes
  return timeoutInSeconds - 30000; // remove half minute to prevent race condition
};

export const getTimeoutFromAuthConfig = ({ key, defaultValue }: { key: string; defaultValue: number }) => {
  const authConfig = JSON.parse(localStorage.getItem('authConfig') || 'null');
  const timeoutValue = !authConfig ? undefined : authConfig[key];
  return timeoutValue ? timeoutValue * 60000 : defaultValue * 60000; // timeout value and default value are in minutes
};

export const setDDUser = user =>
  datadogRum.setUser({
    ...datadogRum.getUser(),
    userId: user.userId,
    name: user.userName,
    email: user.email,
    roleId: user.roleId,
    roleName: user.roleName,
    accountId: user.accountId,
    role: user.role,
  });

export const removeCognitoLS = () => {
  const cognitoLSValues = Object.keys(localStorage).filter(key => /^CognitoIdentityServiceProvider(.*)$/.test(key));
  for (let i = 0; i < cognitoLSValues.length; i++) {
    const key = cognitoLSValues[i];
    localStorage.removeItem(key);
  }
};

export const setAccountIdLocalStorage = id => {
  if (id) {
    localStorage.setItem('accountId', id);
  } else {
    localStorage.removeItem('accountId');
  }
};
