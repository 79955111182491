import React, { useEffect, useMemo, useState } from 'react';
import { alpha, Avatar, Box, ClickAwayListener, Fade, TextField, Typography, useAutocomplete, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PathFeatureFlag, PUBLIC_APPS } from '../../types';
import { App } from '../../types/sideBar.types';
import { capitalizeSentence } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import { appsSideBar } from './sidebar.constants';
import { ReactComponent as Search } from '../../assets/Search.svg';

interface Props {
  apps: App[];
}

const SpotlightSearch: React.FC<Props> = ({ apps }) => {
  const { palette } = useTheme() as any;
  const {
    getPathName,
    accountData,
    featureFlags,
    isBackOfficeAvailable,
    setSelectedApp,
    userPermissions: { hasAllowedPermission, isAvalorAdmin },
    user,
    setAccountId,
  } = useAvContext();
  const navigate = useNavigate();
  const client = useQueryClient();
  const [showSpotlight, setShowSpotlight] = useState(false);
  useEffect(() => {
    window.addEventListener('keydown', e => {
      if (e.code === 'KeyK' && e.metaKey) {
        setShowSpotlight(true);
      } else if (e.code === 'Escape') {
        setShowSpotlight(false);
      }
    });
  }, []);

  const isAllowedPath = (path, isPathBackOffice, permissionAppName) =>
    (hasAllowedPermission({ path, app: permissionAppName }) &&
      (!PathFeatureFlag[path] || featureFlags[PathFeatureFlag[path]]) &&
      !isPathBackOffice) ||
    (isBackOfficeAvailable && isPathBackOffice);
  const ACCOUNT_GROUP = 'Accounts';

  const pages = useMemo(() => {
    const allApps = [...PUBLIC_APPS, ...apps.map(app => app.id)];

    return [
      ...Object.keys(appsSideBar)
        .reduce(
          (acc, k) => [
            ...acc,
            ...appsSideBar[k]
              .map(i => ({ ...i, appUrl: k }))
              .flatMap(r =>
                r.subMenu
                  .flatMap(s => (s.subMenu ? s.subMenu.map(subMenu => ({ ...subMenu, parentPath: s.path })) : s))
                  .filter(subMenuItem => isAllowedPath(subMenuItem.path, subMenuItem.isBackOffice, k))
                  .map(s => ({
                    ...s,
                    appPath: r.appUrl,
                    parent: r.name,
                    path: s.parentPath ? `${s.parentPath}/${s.path}` : s.path,
                  }))
              ),
          ],
          [] as any[]
        )
        .map(r => ({
          group: r.appPath,
          value: `${r.appPath}/${r.path}`,
          pagePath: r.path,
          title: r.name === 'Settings' ? capitalizeSentence(`${r.parent.toLowerCase()} ${r.name}`) : capitalizeSentence(`${r.name}`),
          icon: r.icon,
        }))
        .filter(o => allApps.includes(o.group)),
      ...(isAvalorAdmin
        ? user.accounts.map(account => ({
            value: account.accountId,
            icon: <Avatar src={`/account-logo/${account.accountId}.png`} alt={account.accountName} sx={{ width: 30, height: 30 }} />,
            group: ACCOUNT_GROUP,
            pagePath: account.accountId,
            title: account.accountName,
          }))
        : []),
    ];
  }, [apps]);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    id: 'spotlight',
    options: pages,
    getOptionLabel: option => option.title,
    groupBy: o => o.group,
    clearOnBlur: true,
    clearOnEscape: true,
    onChange: (event, option) => {
      event.stopPropagation();
      if (option) {
        if (option.group === ACCOUNT_GROUP) {
          if (option.value !== user.accountId) {
            setAccountId(option.value);
            client.clear();
            setSelectedApp(APP_PATHS[accountData.apps[0]?.name] || '');
            navigate(`/${option.value}`);
          }
        } else {
          navigate(getPathName(option?.pagePath, '', option?.group));
        }
        setShowSpotlight(false);
      }
    },
  });

  const isRenderOptions = (getInputProps().value as string)?.length > 0;

  return (
    showSpotlight && (
      <ClickAwayListener onClickAway={() => setShowSpotlight(false)}>
        <Fade in={showSpotlight}>
          <Box
            sx={{
              position: 'absolute',
              right: 'calc(50vw - 250px)',
              top: 200,
              width: '500px',
              background: alpha(palette.colors.neutrals[600], 0.85),
              p: 3,
              borderRadius: 2,
              zIndex: theme => theme.zIndex.tooltip,
              color: palette.white.main,
              input: {
                fontSize: 26,
                color: palette.white.main,
                '&.MuiInputBase-input::placeholder': { color: palette.colors.neutrals[400] },
              },
              'fieldset.MuiOutlinedInput-notchedOutline': { border: 'none' },
              ul: { listStyleType: 'none', paddingInlineStart: 1 },
            }}
            {...getRootProps()}>
            <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
              <Search />
              {/* @ts-ignore */}
              <TextField
                {...getInputProps()}
                autoFocus
                onBlur={() => setShowSpotlight(false)}
                placeholder="Search Page"
                onFocusCapture={e => (e.target as HTMLInputElement).select()}
              />
            </Box>
            <Box component="ul" {...getListboxProps()} sx={{ maxHeight: 500, overflow: 'auto' }}>
              {groupedOptions.length > 0 &&
                isRenderOptions &&
                groupedOptions.map(group => (
                  <Box key={group.group} component="li">
                    <Typography variant="h5" sx={{ my: 1 }}>
                      {capitalizeSentence(group.group)}
                    </Typography>
                    <Box component="ul" sx={{ ...flex.col, gap: 1 }}>
                      {group.options.map((option, index) => (
                        <Box
                          key={option.title}
                          component="li"
                          sx={{
                            ...flex.itemsCenter,
                            gap: 2,
                            p: 1,
                            borderRadius: 2,
                            cursor: 'pointer',
                            '&.Mui-focused, &:active': {
                              backgroundColor: palette.colors.primary[400],
                            },
                          }}
                          {...getOptionProps({ option, index: index + group.index })}>
                          {option.icon}
                          {option.title}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </Fade>
      </ClickAwayListener>
    )
  );
};

export default SpotlightSearch;
