// eslint-disable-next-line import/prefer-default-export
export const SearchContextKeys = {
  Tickets: 'tickets',
  Incidents: 'incidents',
  Assets: 'assets',
  Findings: 'findings',
  Pivot: 'pivot',
  RemediationDashboard: 'remediation',
  RiskDashboard: 'risk',
  Factor: 'factor',
  Alerts: 'alerts',
  SmallTicketsTable: 'smallTicketsTable',
};
