import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useAvContext } from '../context/AvContextProvider';
import { Permission } from '../types';
import { entityViewConfig } from '../utils/mapping';
import { createRoutes } from './utils';

const Tickets = lazy(() => import('../views/Tickets/Tickets'));
const VisualExplorer = lazy(() => import('../views/Tickets/VisualExplorer'));

const routesDef = projectionName => [
  { path: '', element: () => <Tickets key={projectionName} projectionName={projectionName} />, permissions: [Permission.READ] },
  { path: ':id', element: () => <Tickets key={projectionName} projectionName={projectionName} />, permissions: [Permission.READ] },
  { path: 'visual', element: () => <VisualExplorer />, permissions: [Permission.READ] },
];

const GeneralEntityRoute = projectionName => {
  const { path } = Object.values(entityViewConfig).find(({ projectionName: p }) => p === projectionName)!;
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  return <Route path={path}>{createRoutes(path, hasAllowedPermission, routesDef(projectionName))}</Route>;
};

export default GeneralEntityRoute;
