import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../components/amplifyProvider';
import { getAccessTokenTimeout, getAccountTokenFromLS, logoutFromAvalor, shouldSkipUILogin, useUserIsActive } from '../utils/auth.utils';
import { noop } from '../utils/Utils';

export const useAccessToken = (getAccessTokenSilently, isAuthenticated, accountId, isOnline) => {
  const [userIsActive, setUserIsActive] = useState(true);
  const timeout = getAccessTokenTimeout();
  useUserIsActive({ setIsNotIdle: setUserIsActive, accessTokenTimeout: timeout });
  const options = {
    enabled: isOnline && userIsActive && isAuthenticated,
    refetchInterval: timeout,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: isOnline,
    staleTime: timeout,
  };
  const accessTokenQuery = useQuery({ queryKey: ['access_token'], queryFn: () => (isOnline ? getAccessTokenSilently(accountId) : Promise.resolve()), ...options });
  return shouldSkipUILogin()
    ? { data: getAccountTokenFromLS(accountId) || localStorage.getItem('accessToken'), isLoading: false, refetch: noop }
    : accessTokenQuery;
};

export const useLogout = (logout, loginWithRedirect) => redirectBack => logoutFromAvalor({ logout, loginWithRedirect, redirectBack });

export const useAuthentications = () => {
  const { isLoading: isLoadingAmplify, isAuthenticated: isAuthenticatedAmplify, getAccessToken, signIn, signOut, user } = useAuth();
  return {
    user,
    isLoading: isLoadingAmplify,
    isAuthenticated: isAuthenticatedAmplify,
    getAccessTokenSilently: getAccessToken,
    logout: signOut,
    loginWithRedirect: signIn,
  };
};
