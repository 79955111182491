import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS, Permission } from '../types';

const Rules = lazy(() => import('../views/Rules/Rules'));
const EditRules = lazy(() => import('../views/Rules/EditRules'));
const RulesLogs = lazy(() => import('../views/Rules/RulesLogs'));
const AuditLogsRule = lazy(() => import('../views/Rules/AuditLogsRule'));
const RuleRuns = lazy(() => import('../views/Rules/RuleRuns'));

const RulesRoute = () => {
  const {
    userPermissions: { hasAllowedPermission, isAvalorAdmin },
  } = useAvContext();
  const path = PAGE_PATHS.RULES;
  const permissionObject = { app: APP_PATHS.DETECTIONS, path };
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <Rules />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ ...permissionObject, permission: Permission.CREATE })}>
            <EditRules />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute
            isAllowed={
              hasAllowedPermission(permissionObject) || hasAllowedPermission({ ...permissionObject, permission: Permission.UPDATE })
            }>
            <EditRules />
          </ProtectedRoute>
        }
      />
      <Route
        path="logs"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <RulesLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="runs/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <RuleRuns />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id"
        element={
          <ProtectedRoute isAllowed={isAvalorAdmin}>
            <AuditLogsRule />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default RulesRoute;
