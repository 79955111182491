import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';

const ModelManagement = lazy(() => import('../views/ModelManagement/ModelManagement'));
const ModelPreview = lazy(() => import('../views/ModelManagement/ModelPreview'));
const ModelManagementRuns = lazy(() => import('../views/ModelManagement/ModelManagementRuns'));
const AuditLogsModelManagement = lazy(() => import('../views/ModelManagement/AuditLogsModelManagement'));

const ModelManagementRoute = () => {
  const {
    userPermissions: { hasAllowedPermission, isAvalorAdmin },
  } = useAvContext();
  const path = PAGE_PATHS.MODEL_MANAGEMENT;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <ModelManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <ModelManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id/preview"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <ModelPreview />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id"
        element={
          <ProtectedRoute isAllowed={isAvalorAdmin}>
            <AuditLogsModelManagement />
          </ProtectedRoute>
        }
      />
      <Route
        path="runs"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <ModelManagementRuns />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default ModelManagementRoute;
