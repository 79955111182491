import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS } from '../types';

const DataExploration = lazy(() => import('../views/Logs/DataExploration'));
const NewLogs = lazy(() => import('../views/Logs/NewLogs'));

const DataExplorationRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const dataExplorationPath = PAGE_PATHS.DATA_EXPLORATION;
  const logsPath = PAGE_PATHS.LOGS;
  return (
    <>
      <Route path={dataExplorationPath}>
        <Route
          path=""
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path: dataExplorationPath })}>
              <DataExploration />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path={logsPath}>
        <Route
          path=""
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path: logsPath })}>
              <NewLogs />
            </ProtectedRoute>
          }
        />
      </Route>
    </>
  );
};

export default DataExplorationRoute;
