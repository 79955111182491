import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../../components/Permissions';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS, Permission } from '../../types';
import MeasurementsRoute from '../MeasurementsRoute';
import ModelManagementRoute from '../ModelManagementRoute';
import NotificationTargetsRoute from '../NotificationTargetsRoute';
import SourcesRoute from '../SourcesRoute';
import { createRoutes } from '../utils';

const Workflows = lazy(() => import('../../views/Workflows/Workflows'));
const Aliases = lazy(() => import('../../views/Aliases/Aliases'));
const DataUnification = lazy(() => import('../../views/DataUnification/DataUnification'));
const RuleSet = lazy(() => import('../../views/DataUnification/RuleSet'));

const routesDef = {
  [PAGE_PATHS.DATA_UNIFICATION]: [
    { path: '', element: () => <DataUnification />, permissions: [Permission.READ] },
    {
      path: 'set/:fieldName?',
      routes: [
        { path: '', element: () => <RuleSet />, permissions: [Permission.CREATE, Permission.UPDATE] },
        { path: 'create', element: () => <RuleSet mode="create" />, permissions: [Permission.CREATE, Permission.UPDATE] },
        { path: 'edit/:id', element: () => <RuleSet mode="edit" />, permissions: [Permission.CREATE, Permission.UPDATE] },
      ],
    },
  ],
};

const Platform = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const workflowsPath = PAGE_PATHS.WORKFLOWS;
  const aliasesPath = PAGE_PATHS.ALIASES;
  return (
    <Route path={APP_PATHS.PLATFORM}>
      {ModelManagementRoute()}
      <Route path={PAGE_PATHS.DATA_UNIFICATION}>
        {createRoutes(PAGE_PATHS.DATA_UNIFICATION, hasAllowedPermission, routesDef[PAGE_PATHS.DATA_UNIFICATION])}
      </Route>
      {SourcesRoute()}
      {NotificationTargetsRoute()}
      {MeasurementsRoute()}

      <Route
        path={workflowsPath}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: workflowsPath })}>
            <Workflows />
          </ProtectedRoute>
        }
      />
      <Route
        path={aliasesPath}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: aliasesPath })}>
            <Aliases />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};
export default Platform;
