import React from 'react';
import { ReactComponent as Clock } from '../../assets/Clock.svg';
import { ReactComponent as Function } from '../../assets/Function.svg';
import { ReactComponent as Group } from '../../assets/Group.svg';

export const POPULATION_COUNT = 'POPULATION_COUNT';
export const TIME_TO = 'TIME_TO';
export const MATH_EXPRESSION = 'MATH_EXPRESSION';
export const UNKNOWN = 'UNKNOWN';
export const POPULATION_AGGS = 'POPULATION_AGGS';
export const PERCENTAGE = 'PERCENTAGE';

export const categoryNumericType = {
  [UNKNOWN]: 0,
  [TIME_TO]: 1,
  [POPULATION_COUNT]: 2,
  [POPULATION_AGGS]: 3,
  [MATH_EXPRESSION]: 4,
  [PERCENTAGE]: 5,
};

export const categoryOptions = [
  { title: 'Population Count', value: POPULATION_COUNT, icon: <Group /> },
  { title: 'Time To', value: TIME_TO, icon: <Clock /> },
  { title: 'Math Expression', value: MATH_EXPRESSION, icon: <Function /> },
];

export const unitOptions = {
  [POPULATION_COUNT]: [{ title: 'Number', value: 'number' }],
  [TIME_TO]: [
    { title: 'Days', value: 'days' },
    { title: 'Weeks', value: 'weeks' },
    { title: 'Months', value: 'months' },
  ],
  [MATH_EXPRESSION]: [
    { title: 'Number', value: 'number' },
    { title: 'Percentage', value: 'percentage' },
  ],
  [UNKNOWN]: [{ title: '-', value: '-' }],
  [POPULATION_AGGS]: [{ title: '-', value: '-' }],
  [PERCENTAGE]: [
    { title: 'Number', value: 'number' },
    { title: 'Percentage', value: 'percentage' },
  ],
};

export const unit = {
  Quantity: { title: 'Number', value: 'number' },
  Days: { title: 'Days', value: 'days' },
  Weeks: { title: 'Weeks', value: 'weeks' },
  Months: { title: 'Months', value: 'months' },
  Percentage: { title: 'Percentage', value: 'percentage' },
};

export type Measurement = {
  builtin: boolean;
  systemName: string;
  displayName: string;
  description: string;
  expression: string;
  timeExpression: string;
  type: number;
  variables: [];
  runtimeArgs: [];
  category: string;
  context: string[];
  useTimeDim: boolean;
  drillDown: {
    fieldName: string;
    projId: {
      name: string;
      builtIn: boolean;
    };
    filter: string;
  };
  unit: {
    name: string;
  };
  createdByUserId: string;
  updatedByUserId: string;
  id: string;
};

export type CustomMeasurement = Pick<
  Measurement,
  'displayName' | 'expression' | 'timeExpression' | 'type' | 'category' | 'context' | 'description'
> &
  Partial<Pick<Measurement, 'systemName' | 'unit'>>;

export enum MODES {
  EDIT_MODE = 'EDIT',
  CREATE_MODE = 'CREATE',
  VIEW_MODE = 'VIEW',
}

export const titleByMode = {
  [MODES.EDIT_MODE]: 'Edit',
  [MODES.CREATE_MODE]: 'Create new',
  [MODES.VIEW_MODE]: '',
};
