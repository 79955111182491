import React, { useContext, useEffect, useState } from 'react';
import { alpha, Box, Divider, Drawer, Fade, List, ListItem, Skeleton, useTheme } from '@mui/material';
import { useAvContext } from '../../context/AvContextProvider';
import { LayoutContext } from '../../context/LayoutContext';
import { FeatureFlags, PathFeatureFlag, pathsByRole, PUBLIC_APPS } from '../../types';
import { isDebugMode } from '../../utils/rum.utils';
import { dotStyle } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { APPBAR_HEIGHT } from './appbar.constants';
import { useEnabledAppsOptions } from './hooks';
import { appsSideBar, SIDEBAR_WIDTH_COLLAPSED, SIDEBAR_WIDTH_OPEN } from './sidebar.constants';
import SideBarItem, { item } from './SideBarItem';
import { OpenedSubMenus } from './types';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down.svg';

interface SectionTitleProps {
  isOpen: boolean;
  loading: boolean;
  name: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ isOpen, loading, name }) => (
  <ListItem sx={{ ...styles.sectionTitle, ...(!isOpen && { px: 2, minHeight: 48 }) }}>
    {loading ? (
      <Skeleton variant="text" height={isOpen ? 20 : 5} width={isOpen ? '40%' : '100%'} />
    ) : isOpen ? (
      name
    ) : (
      <Fade in timeout={800}>
        <Divider />
      </Fade>
    )}
  </ListItem>
);

const AvSidebar = () => {
  const {
    layoutWidths: { sideBar: sidebarWidth },
    setLayoutWidths,
  } = useContext(LayoutContext);
  const {
    featureFlags,
    isBackOfficeAvailable,
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const isNewJWT = featureFlags[FeatureFlags.NewJwt];
  const { user, isLoading, selectedApp } = useAvContext();
  const enabledApps = useEnabledAppsOptions();
  const [isOpen, setIsOpen] = useState(sidebarWidth === SIDEBAR_WIDTH_OPEN);
  const [isHovered, setIsHovered] = useState(false);
  const [openedSubMenus, setOpenedSubMenus] = useState<OpenedSubMenus[]>([]);

  useEffect(() => {
    setOpenedSubMenus(prev => prev.filter(v => v.app === selectedApp));
  }, [selectedApp]);

  const [shouldDisableHover, setShouldDisableHover] = useState(false);

  useEffect(() => setLayoutWidths('sideBar', isOpen ? SIDEBAR_WIDTH_OPEN : SIDEBAR_WIDTH_COLLAPSED), [isOpen]);

  return (
    <Drawer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={theme => styles.drawer(theme, sidebarWidth, isOpen, shouldDisableHover)}
      anchor="left"
      variant="permanent">
      <SideBarToggler open={isOpen} toggle={() => setIsOpen(!isOpen)} setShouldDisableHover={setShouldDisableHover} />
      {(!!enabledApps.some(({ id }) => id === selectedApp) || PUBLIC_APPS.includes(selectedApp)) &&
        appsSideBar[selectedApp]?.map(({ name, subMenu }) => {
          const hasChild = subMenu.some(
            ({ path }) =>
              (isNewJWT ? hasAllowedPermission({ path, app: selectedApp }) : pathsByRole[user.role]?.[path]?.READ) &&
              (!PathFeatureFlag[path] || featureFlags[PathFeatureFlag[path]])
          );
          const expandDrawer = isOpen || isHovered;
          return (
            <React.Fragment key={name}>
              {(isLoading || hasChild) && <SectionTitle isOpen={expandDrawer} loading={isLoading} name={name} />}
              {isLoading ? (
                <List>
                  {['70%', '60%'].map(percentage => (
                    <ListItem key={percentage} sx={theme => styles.item(theme)}>
                      <Skeleton variant="text" height={isOpen ? 20 : 30} width={isOpen ? percentage : '100%'} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                hasChild && (
                  <List sx={{ ...flex.col, gap: 1 }}>
                    {subMenu
                      .filter(item => !item.isBackOffice || isBackOfficeAvailable)
                      .map(subMenuItem => (
                        <SideBarItem
                          isOpen={expandDrawer}
                          {...subMenuItem}
                          key={`${subMenuItem.name}_${selectedApp}_${subMenuItem.path}`}
                          {...{ openedSubMenus, setOpenedSubMenus }}
                        />
                      ))}
                  </List>
                )
              )}
            </React.Fragment>
          );
        })}
      {isDebugMode() && isOpen && (
        <>
          <span style={{ flexGrow: 1 }} />
          <List>
            <ListItem sx={styles.sectionTitle}>
              <Divider />
            </ListItem>
            <ListItem sx={styles.sectionTitle}>
              <AvTooltip>
                <>Version: {import.meta.env.VITE_VERSION}</>
              </AvTooltip>
            </ListItem>
          </List>
        </>
      )}
    </Drawer>
  );
};
export default AvSidebar;

const styles = {
  drawer: (theme, sidebarWidth, isOpen, shouldDisableHover) => ({
    '+ main': { paddingTop: `${APPBAR_HEIGHT}px` },
    zIndex: theme.zIndex.drawer + 5,
    width: sidebarWidth,
    transition: theme.transitions.create(['width', 'box-shadow']),
    ...(shouldDisableHover && { pointerEvents: 'none' }),
    '.MuiDrawer-paper': {
      boxShadow: 'none',
      ':hover': {
        boxShadow: '0px 2px 20px rgba(18, 18, 27, 0.15)',
        borderRight: theme => `1px solid ${theme.palette.colors.primary[500]}`,
        ...(!isOpen && {
          width: SIDEBAR_WIDTH_OPEN,
        }),
      },
      width: sidebarWidth,
      backgroundColor: theme.palette.colors.neutrals[100],
      color: `${theme.palette.colors.neutrals[100]}B3`,
      padding: '16px 0',
      marginTop: `${APPBAR_HEIGHT}px`,
      transition: theme.transitions.create(['width']),
      whiteSpace: 'nowrap',
      overflowX: 'clip',
      '.av-sidebar-toggler': {
        opacity: 0,
        transition: theme.transitions.create(['left', 'opacity', 'background-color']),
      },
      '&:hover': {
        '.av-sidebar-toggler': {
          opacity: 1,
          border: theme => `1px solid ${theme.palette.colors.primary[500]}`,
          backgroundColor: theme => theme.palette.colors.neutrals[200],
          left: SIDEBAR_WIDTH_OPEN,
        },
      },
    },
  }),
  sectionTitle: {
    color: theme => theme.palette.colors.neutrals[500],
    height: 48,
    padding: '16px 24px',
    letterSpacing: '0.7px',
    marginTop: '2px',
    fontWeight: 600,
    fontSize: 12,
    opacity: 0.7,
    '& hr': {
      backgroundColor: theme => theme.palette.colors.neutrals[350],
      height: '1px',
      border: 'none',
      borderRadius: '12px',
      width: '100%',
    },
    '& + ul': {
      padding: 0,
    },
  },
  item,
};

interface SideBarTogglerProps {
  open: boolean;
  toggle: () => void;
  setShouldDisableHover: React.Dispatch<React.SetStateAction<boolean>>;
}
const SideBarToggler: React.FC<SideBarTogglerProps> = ({ open, toggle, setShouldDisableHover }) => {
  const {
    layoutWidths: { sideBar: sidebarWidth = SIDEBAR_WIDTH_OPEN },
  } = useContext(LayoutContext);
  const theme = useTheme();
  const dotSx = dotStyle(theme => alpha(theme.palette.colors.neutrals[700], 0.1), 24);
  const sideBarTogglerStyle = {
    ...flex.center,
    ...dotSx,
    position: 'fixed',
    top: 30 + APPBAR_HEIGHT,
    left: sidebarWidth,
    transform: `translateX(-50%) rotate(${open ? '' : '-'}90deg)`,
    cursor: 'pointer',
    zIndex: theme => theme.zIndex.modal,
    backdropFilter: 'blur(15.5676px)',
    ':hover': {
      background: theme => alpha(theme.palette.primary.main, 0.2),
    },
    svg: {
      width: 16,
      height: 16,
      color: theme => theme.palette.colors.neutrals[700],
    },
  };

  return (
    <Box
      className="av-sidebar-toggler"
      sx={sideBarTogglerStyle}
      onClick={() => {
        toggle();
        if (open) {
          setShouldDisableHover(true);
          setTimeout(() => setShouldDisableHover(false), theme.transitions.duration.standard);
        }
      }}>
      <ArrowDown />
    </Box>
  );
};
