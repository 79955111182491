import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';

const Sources = lazy(() => import('../views/Sources/Sources'));
const EditSources = lazy(() => import('../views/Sources/EditSources'));
const SourceRuns = lazy(() => import('../views/Sources/SourceRuns'));
const PickSource = lazy(() => import('../views/Sources/PickSource'));
const Mapping = lazy(() => import('../views/Sources/Mapping/Mapping'));
const MappingPreview = lazy(() => import('../views/Sources/MappingPreview'));
const AuditLogs = lazy(() => import('../views/Sources/SourceAuditLogs'));
const MappingAuditLogs = lazy(() => import('../views/Sources/Mapping/MappingAuditLogs'));

const SourcesRoute = () => {
  const {
    userPermissions: { hasAllowedPermission, isAvalorAdmin },
  } = useAvContext();
  const path = PAGE_PATHS.SOURCES;
  const mappingPath = PAGE_PATHS.SOURCES_MAPPING;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <Sources />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <PickSource />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/:type/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <EditSources />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/:type/:previewId"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <EditSources />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <EditSources />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id/:previewId"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <EditSources />
          </ProtectedRoute>
        }
      />
      <Route
        path="runs/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <SourceRuns />
          </ProtectedRoute>
        }
      />
      <Route
        path={`:id/${mappingPath}/:type`}
        element={
          <ProtectedRoute
            isAllowed={
              hasAllowedPermission({ path: mappingPath }) || hasAllowedPermission({ path: mappingPath, permission: Permission.UPDATE })
            }>
            <Mapping />
          </ProtectedRoute>
        }
      />
      <Route
        path={`:id/${mappingPath}/:type/preview`}
        element={
          <ProtectedRoute
            isAllowed={
              hasAllowedPermission({ path: mappingPath }) || hasAllowedPermission({ path: mappingPath, permission: Permission.UPDATE })
            }>
            {' '}
            <MappingPreview />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id"
        element={
          <ProtectedRoute isAllowed={isAvalorAdmin}>
            <AuditLogs />
          </ProtectedRoute>
        }
      />
      <Route
        path="audit/:id/data-mapping/:type"
        element={
          <ProtectedRoute isAllowed={isAvalorAdmin}>
            <MappingAuditLogs />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};
export default SourcesRoute;
