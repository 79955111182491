import React, { useMemo } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useAvContext } from '../../context/AvContextProvider.tsx';
import { useExportQuerySqlWorkflow } from '../../hooks/useExportQueryWorklflow.ts';
import { FeatureFlags } from '../../types/index.ts';
import { isPercentageMetric, ticketDimensionsOptions } from '../../utils/dashboardDataUtils';
import { entityViewConfig } from '../../utils/mapping.ts';
import { noop, orderByKey,refetchingStyle } from '../../utils/Utils';
import { explodeFields, getFieldLabel } from '../../views/Tickets/ticket.types';
import { getOptionsFromFields } from '../AvFilters.tsx';
import AvLegend from '../AvLegend.tsx';
import { flex } from '../AvThemeProvider.tsx';
import AvTooltip from '../AvTooltip.tsx';
import ExportButton from '../ExportButton.tsx';
import Select from '../Select';
import AvTable from '../Table/AvTable.tsx';
import { WidgetTitle } from './layout.components.tsx';
import { ReactComponent as Plus } from '../../assets/Plus.svg';
import { ReactComponent as X } from '../../assets/X.svg';

const PAGE_SIZE = 10;
const limit = 8;
export function OverTimeTableHeader({ metrics, sql, updateFilters, rowDim, colDim, title, metricOptions }) {
  const {
    featureFlags,
    accountEntities: { aggProjs, fieldMap },
  } = useAvContext();
  const { mutate: exportSqlWorkflow, isLoading: isExportingWorkflow } = useExportQuerySqlWorkflow();

  const FilterTypesLabel = entityViewConfig.Ticket.labelsV2;
  const updateAnalyzeProps = (field, value) => {
    const fullProps = { metrics, rowDim, colDim, [field]: value };
    updateFilters('analyze', [encodeURIComponent(JSON.stringify(fullProps))]);
  };
  const dimensionOptions = !featureFlags[FeatureFlags.ShowDisplayNamesFromModel]
    ? Object.keys(FilterTypesLabel).map(field => ({
        title: fieldMap[field] ? getFieldLabel({ field, fieldMap, projectionName: 'tickets' }) : FilterTypesLabel[field],
        value: field,
      }))
    : ticketDimensionsOptions(FilterTypesLabel);

  return (
    <Box sx={{ ...flex.colItemsStart }}>
      <WidgetTitle marginBottom={2}>{title}</WidgetTitle>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        <Select
          variant="outlined"
          type="icon"
          isRequired
          label={<Plus />}
          options={metricOptions}
          size="xSmall"
          isMultiple
          onChange={v => updateAnalyzeProps('metrics', v.length ? v : metricOptions[0].value)}
          value={metrics}
          showSelection={false}
          disabled={metrics.length >= limit}
          disabledTooltipText={`Selection is limited to ${limit} metrics`}
          shouldCloseOnChange={newVal => newVal?.length >= limit}
        />
        <AvLegend
          isHorizontal
          isOutlined
          series={metrics.map((m, index, arr) => {
            const name = metricOptions.find(({ value }) => value === m)?.title;
            return {
              name,
              component: (
                <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                  {name}
                  {arr.length > 1 && (
                    <IconButton
                      onClick={() =>
                        updateAnalyzeProps(
                          'metrics',
                          metrics.filter(metric => metric !== m)
                        )
                      }>
                      <X />
                    </IconButton>
                  )}
                </Box>
              ),
            };
          })}
        />
        <Box sx={{ px: '2px' }}>By</Box>
        <Select
          variant="outlined"
          isRequired
          options={[...dimensionOptions, ...getOptionsFromFields(aggProjs.tickets.fields, null, 'ticket')]}
          size="xSmall"
          onChange={v => updateAnalyzeProps('rowDim', v.length ? v : metricOptions[0].value)}
          value={rowDim}
        />
        <Box>
          <ExportButton
            onClick={() => exportSqlWorkflow({ querySql: sql, projectionID: { name: 'tickets', builtIn: true } })}
            loading={isExportingWorkflow}
            width={18}
          />
        </Box>
      </Box>
    </Box>
  );
}

OverTimeTableHeader.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.string),
  updateFilters: PropTypes.func,
  rowDim: PropTypes.string,
  colDim: PropTypes.string,
  title: PropTypes.string,
  sql: PropTypes.string,
  metricOptions: PropTypes.arrayOf(PropTypes.shape()),
};

OverTimeTableHeader.defaultProps = {
  metrics: [],
  updateFilters: noop,
  rowDim: undefined,
  colDim: undefined,
  title: undefined,
  sql: undefined,
  metricOptions: [],
};

function OverTimeTable({ data, totals, metrics, rowDim, colDim, page, isLoading, dateFormat, sort, metricOptions, updateFilters }) {
  const theme = useTheme();
  const FilterTypesLabel = entityViewConfig.Ticket.labelsV2;
  const groupedHeadCells = [...new Set(data[0].map(r => (colDim === 'date' ? format(new Date(r[colDim]), dateFormat) : r[colDim])))];
  const { rows, headCells } = useMemo(() => {
    const { rows, headCells } = convertRowsToPivot(data[0], rowDim, colDim, groupedHeadCells, metrics, metricOptions, explodeFields());
    const { property, isAsc } = sort?.[0] || {};
    return { rows: property ? orderByKey(rows, property, isAsc) : rows, headCells };
  }, [data]);

  const { rows: totalRow, headCells: totalHeadCells } = useMemo(
    () => convertRowsToPivot(data[1], rowDim, colDim, groupedHeadCells, metrics, metricOptions, explodeFields()),
    [data]
  );
  const rowDimLength = [1];
  const smallHeaderFormat = { width: 100, span: { whiteSpace: 'normal !important' } };
  const borderFormat = { borderRight: `1px solid ${theme.palette.colors.neutrals[350]}`, ...smallHeaderFormat };

  const rowDimId = explodeFields()[rowDim]?.group || rowDim;
  const tableHeadCells = [
    {
      id: rowDimId,
      label: FilterTypesLabel[rowDim],
      isKey: true,
      sx: borderFormat,
      style: {
        ...borderFormat,
        maxWidth: 200,
        maxHeight: 200,
        borderRight: `1px solid ${theme.palette.colors.neutrals[350]}`,
      },
      formatter: tooltip,
      ...(sort[0]?.property === rowDimId ? { sort: sort[0].isAsc ? 'asc' : 'desc' } : {}),
    },
    ...headCells.map(({ key, label }, index, arr) => {
      const style = (index + rowDimLength.length) % metrics.length === 0 && index !== arr.length - 1 ? borderFormat : smallHeaderFormat;
      return {
        id: key,
        label,
        sx: style,
        style,
        type: isPercentageMetric(key) ? 'percentage' : 'number',
        ...(sort[0]?.property === key ? { sort: sort[0].isAsc ? 'asc' : 'desc' } : {}),
      };
    }),
  ];

  const updateAnalyzeProps = (field, value) => {
    const fullProps = { metrics, rowDim, colDim, [field]: value };
    updateFilters('analyze', [encodeURIComponent(JSON.stringify(fullProps))]);
  };

  const handleSort = (property, direction) => {
    const isAsc = direction === 'asc';
    const fullProps = { metrics, rowDim, colDim, sort: [{ property, isAsc }] };
    updateFilters('analyze', [encodeURIComponent(JSON.stringify(fullProps))]);
  };

  return (
    <AvTable
      hasHeaderFilters={false}
      size="xSmall"
      rowLimit={PAGE_SIZE}
      enableSelectionColor={false}
      allowEdit={false}
      headCells={tableHeadCells}
      loading={isLoading}
      rows={rows}
      onSort={handleSort}
      pagination
      paginationProps={{
        onPageChange: page => updateAnalyzeProps('page', page),
        totalRows: totals / groupedHeadCells.length || 0,
        pageNum: page,
      }}
      groupedHeadCells={{ cells: groupedHeadCells, innerCols: metrics.length }}
      totalProps={
        data?.[1]
          ? {
              colspan: 0,
              label: 'Summary',
              row: totalRow[0],
              cells: totalHeadCells.map(({ key }) => ({
                id: key,
                type: isPercentageMetric(key) ? 'percentage' : 'number',
              })),
            }
          : {}
      }
      sx={{ ...(isLoading && refetchingStyle) }}
    />
  );
}

export default OverTimeTable;

OverTimeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape())),
  totals: PropTypes.number,
  metrics: PropTypes.arrayOf(PropTypes.string),
  rowDim: PropTypes.string,
  colDim: PropTypes.string,
  page: PropTypes.string,
  isLoading: PropTypes.bool,
  dateFormat: PropTypes.string,
  metricOptions: PropTypes.arrayOf(PropTypes.shape()),
  updateFilters: PropTypes.func,
  sort: PropTypes.arrayOf(PropTypes.shape({ property: PropTypes.string, isAsc: PropTypes.bool })),
};

OverTimeTable.defaultProps = {
  data: [],
  totals: 0,
  metrics: [],
  rowDim: undefined,
  colDim: undefined,
  page: '0',
  isLoading: undefined,
  dateFormat: 'MMM',
  metricOptions: [],
  updateFilters: noop,
  sort: [],
};

const convertRowsToPivot = (data, rowDim, colDim, groupedHeadCells, metricKeys, metricOptions, explodeFields) => {
  // const rowValues = [...new Set(data.map(r => r[rowDim]))];
  const orderedHeadCells = [];
  const headCellsKeys = {};
  const rows = data.reduce((acc, row) => {
    const rowDimKey = explodeFields[rowDim]?.group || rowDim;
    if (!acc[row[rowDimKey]]) {
      const boolFieldValue = typeof row[rowDimKey] === 'boolean' ? (row[rowDimKey] ? 'True' : 'False') : undefined;
      acc[row[rowDimKey]] = {
        [rowDimKey]: boolFieldValue || row[rowDimKey],
      };
    }

    const metricValues = {};
    metricKeys.forEach(m => {
      const key = `${m}-${row[colDim]}`;
      metricValues[key] = row[m];
      if (!headCellsKeys[key]) {
        headCellsKeys[key] = true;
        orderedHeadCells.push({ key, label: metricOptions?.find(o => o.value === m).title || m });
      }
    });

    acc[row[rowDimKey]] = {
      ...acc[row[rowDimKey]],
      ...metricValues,
    };
    return acc;
  }, {});
  return { rows: Object.values(rows), headCells: orderedHeadCells };
};

const tooltip = v => <AvTooltip>{v || 'None'}</AvTooltip>;
