import React from 'react';
import { Route } from 'react-router-dom';
import { APP_PATHS } from '../../types';
import CustomDashboardsRoute from '../CustomDashboardsRoute';
import DataExplorationRoute from '../DataExplorationRoute';
import DataLakeQueriesRoute from '../DataLakeQueriesRoute';
import EntitiesRoute from '../EntitiesRoute';
import ReportsRoute from '../ReportsRoute';

const Explore = () => (
  <Route path={APP_PATHS.EXPLORE}>
    {EntitiesRoute()}
    {DataExplorationRoute()}
    {ReportsRoute()}
    {DataLakeQueriesRoute()}
    {CustomDashboardsRoute()}
  </Route>
);
export default Explore;
