import React, { useEffect, useState } from 'react';
import { alpha, Box, Button, useTheme } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { flex } from './AvThemeProvider';
import TextInput from './TextInput';
import { ReactComponent as FullLogo } from '../assets/Full_Logo.svg';
import loginBg from '../assets/login_bg.svg';

export interface AuthConfiguration {
  userPoolId: string;
  userPoolWebClientId: string;
  userPoolRegion: string;
  userPoolDomain: string;
}

interface LoginProps {
  setAuthConfig: (config: AuthConfiguration) => void;
}

const Login: React.FC<LoginProps> = ({ setAuthConfig }) => {
  const { palette } = useTheme();
  const [params] = useSearchParams();
  const domainParam = params.get('domain');
  const [email, setEmail] = useState();
  const navigate = useNavigate();

  const {
    isPending,
    error,
    mutate: submit,
  } = useMutation({
    mutationKey: ['auth-config', email, domainParam],
    mutationFn: () =>
      fetch(
        `${import.meta.env.VITE_WEBSERVER_API_URL}/auth-config/${
          domainParam ? `domain-login?domain=${domainParam}` : `login?email=${email}`
        }`
      ).then(async res => {
        const result = await res.json();
        if (res.ok) {
          return result;
        }
        if (domainParam) {
          navigate('/'); // navigate back to login
        }
        throw result;
      }),
    onSuccess: result => {
      localStorage.setItem('authConfig', JSON.stringify(result));
      setAuthConfig(result);
    },
  });

  useEffect(() => {
    if (domainParam) {
      submit();
    }
  }, []);

  const style = {
    ...flex.center,
    backgroundImage: `url(${loginBg}), linear-gradient(45deg, ${palette.colors.primary[900]} 0%, ${palette.colors.primary[800]} 210%)`,
    backgroundSize: 'cover',
    backgroundBlendMode: 'overlay',
    form: {
      ...flex.colCenter,
      gap: 3,
      background: alpha(palette.white.main.toString(), 0.1),
      backdropFilter: 'blur(25px)',
      width: 430,
      padding: 5,
      borderRadius: '2px',
      '.MuiOutlinedInput-root': {
        color: palette.colors.neutrals[100],
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: alpha(palette.colors.neutrals[100], 0.2),
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.colors.primary[300],
        },
      },
      '.MuiInputLabel-root.Mui-focused': {
        color: palette.colors.primary[300],
      },
    },
  };

  return (
    !domainParam && (
      <Box component="main" sx={style}>
        <form
          className="fluid-container"
          onSubmit={e => {
            e.preventDefault();
            submit();
          }}>
          <FullLogo style={{ width: 193, height: 42 }} />
          <TextInput
            type="email"
            label="Email address"
            autoCorrect="false"
            value={email}
            inputMode="email"
            autoComplete="email"
            autoCapitalize="none"
            spellCheck="false"
            onChange={setEmail}
            error={(error as any)?.message}
            sx={{ maxWidth: 'unset' }}
          />
          <Button variant="contained" type="submit" fullWidth disabled={isPending}>
            Continue
          </Button>
        </form>
      </Box>
    )
  );
};

export default Login;
