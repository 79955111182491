import { SearchContextKeys } from '../context/SearchContext.types';
import { APP_PATHS, PAGE_PATHS } from '../types';
import { ScreenType } from '../types/savedViews.types';

export const TicketType = {
  default: 'DEFAULT',
  AppSec: 'APP_SEC',
  AssetOriginated: 'ASSET_ORIGINATED',
  CrowdstrikeDetection: 'DETECTION',
  Sast: 'SAST',
  Legit: 'LEGIT',
  ApiiroSecrets: 'APIIRO_SECRETS',
  FileRisk: 'FILE_RISK',
  ManualIncident: 'MANUAL_INCIDENT',
  DefenderIncident: 'DEFENDER_INCIDENT',
  AlertToIncident: 'ALERT_TO_INCIDENT',
  Cyera: 'CYERA',
};

export const FilterTypes = {
  Tags: 'ticket.tags',
  Assignee: 'ticket.owner',
  Artifact: 'ticket.artifact',
  Asset: 'ticket.asset_keys',
  AssetType: 'ticket.asset_types',
  ArtifactType: 'ticket.artifact_type',
  Tactic: 'ticket.tactic',
  Technique: 'ticket.technique',
  Integration: 'ticket.integration_info.key',
  Exception: 'ticket.exception_status.status',
  Username: 'ticket.username',
  TagsV2: 'ticket.tags',
  AssigneeV2: 'ticket.assignee_id',
  ArtifactV2: 'ticket.primary_component.name',
  AssetV2: 'NEIGHBOR_ATTR(finding.asset_key)',
  AssetTypeV2: 'NEIGHBOR_ATTR(finding.asset_type)',
  ArtifactTypeV2: 'ticket.primary_component.type',
  TacticV2: 'ticket.tactic',
  TechniqueV2: 'ticket.technique',
  UsernameV2: 'ticket.username',
  IncidentAssigneeV2: 'incident.assignee_id',
  IncidentTacticV2: 'alert.tactic_name',
  IncidentTechniqueV2: 'alert.technique_name',
};

export const FilterTypesLabelV2 = {
  [FilterTypes.TagsV2]: 'Tags',
  [FilterTypes.AssigneeV2]: 'Assignee',
  [FilterTypes.AssetV2]: 'Asset',
  [FilterTypes.ArtifactV2]: 'Primary Component Name',
  [FilterTypes.AssetTypeV2]: 'Asset Type',
  [FilterTypes.ArtifactTypeV2]: 'Primary Component Type',
};

export const FilterIncidentEntityTypesLabelV2 = {
  [FilterTypes.IncidentAssigneeV2]: 'Assignee',
  [FilterTypes.IncidentTacticV2]: 'Tactic',
  [FilterTypes.IncidentTechniqueV2]: 'Technique',
};

export const defaultSortBy = (entityName = 'ticket') => [
  { property: `${entityName}.severity_score`, isAsc: false },
  { property: 'sla', isAsc: true },
  { property: `${entityName}._key`, isAsc: true },
];

export const defaultAlertSortBy = () => [
  { property: `alert.last_seen`, isAsc: false },
  { property: 'sla', isAsc: true },
  { property: `alert._key`, isAsc: true },
];

export const ACTIONS_MAP = {
  UPDATE: 'update',
  MERGE: 'merge',
  COMMENT: 'comment',
  OUTEGRATION_CREATE: 'outegration_create',
  ALERT_TO_INCIDENT: 'alert_to_incident',
  ATTACHMENTS: 'attachments',
  OUTEGRATION_ACT: 'outegration_act',
};

export const entityViewConfig = {
  Ticket: {
    path: PAGE_PATHS.TICKETS,
    entityTypeId: 'Ticket',
    projectionName: 'tickets',
    pageTitle: 'Remediation Hub',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.Tickets,
    labelsV2: FilterTypesLabelV2,
    searchFields: ['ticket.title'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Tickets,
    vulnsField: 'ticket.finding_count',
    extraFilters: ["ticket.state != 'INACTIVE'"],
    groupingKeyField: 'finding.grouping_key',
    titleFieldName: 'title',
    extraRelationGroup: 'Assignee',
    isTicketFilters: true,
    defaultSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.MERGE]: true,
        [ACTIONS_MAP.COMMENT]: true,
        [ACTIONS_MAP.OUTEGRATION_CREATE]: true,
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: {
        [ACTIONS_MAP.ATTACHMENTS]: true,
      },
    },
    styles: {
      isTransparentPageBackground: true,
    },
  },
  Asset: {
    path: PAGE_PATHS.ASSETS,
    entityTypeId: 'Asset',
    projectionName: 'uber_assets',
    pageTitle: 'Assets',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.AssetView,
    labelsV2: FilterTypesLabelV2,
    defaultFilters: {
      'asset.source_names': [],
      'asset.type': [],
      'asset.site': [],
      'asset.os': [],
    },
    searchFields: ['asset.name'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Assets,
    vulnsField: '',
    extraFilters: [],
    hideCommentsTab: true,
    hideActivityTab: true,
    extraRelationGroup: 'Owner',
    titleFieldName: 'name',
    defaultSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        // Added to support Act button [AVA-9992] fixed in [AVA-10084]
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: true,
    },
  },
  Incident: {
    path: PAGE_PATHS.INCIDENTS,
    entityTypeId: 'Incident',
    projectionName: 'incidents',
    pageTitle: 'Incidents',
    app: 'Incidents',
    appPath: APP_PATHS.DETECTIONS,
    viewScreenType: ScreenType.Incidents,
    labelsV2: FilterIncidentEntityTypesLabelV2,
    searchFields: ['incident.title'],
    defaultSavedView: 'Open Tickets',
    searchContextKey: SearchContextKeys.Incidents,
    groupingKeyField: 'alert.grouping_key',
    titleFieldName: 'title',
    vulnsField: 'incident.alert_count',
    extraFilters: ["incident.state NOT IN ('INACTIVE', 'ARCHIVED')"],
    isTicketFilters: true,
    typesForUIConfig: [
      TicketType.CrowdstrikeDetection,
      TicketType.FileRisk,
      TicketType.ManualIncident,
      TicketType.DefenderIncident,
      TicketType.AlertToIncident,
    ],
    defaultSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.MERGE]: true,
        [ACTIONS_MAP.COMMENT]: true,
        [ACTIONS_MAP.OUTEGRATION_CREATE]: true,
        [ACTIONS_MAP.OUTEGRATION_ACT]: true,
      },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: true },
    },
    styles: {
      isTransparentPageBackground: true,
    },
  },
  Alert: {
    path: PAGE_PATHS.ALERTS,
    entityTypeId: 'Alert',
    projectionName: 'uber_alerts',
    pageTitle: 'Alerts',
    app: 'Incidents',
    appPath: APP_PATHS.DETECTIONS,
    viewScreenType: ScreenType.Alerts,
    labelsV2: FilterIncidentEntityTypesLabelV2,
    searchFields: ['alert.title'],
    defaultSavedView: 'Active',
    searchContextKey: SearchContextKeys.Alerts,
    titleFieldName: 'rule_name',
    defaultFilters: {
      'alert.severity': ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'],
      'alert.state': [],
      'alert.created': [],
      'alert.tactic_name': [],
      'alert.technique_name': [],
    },
    hideCommentsTab: true,
    hideActivityTab: true,
    vulnsField: '',
    defaultSortBy: defaultAlertSortBy,
    actions: {
      grid: {
        [ACTIONS_MAP.UPDATE]: true,
        [ACTIONS_MAP.ALERT_TO_INCIDENT]: true,
      },
      panel: {
        [ACTIONS_MAP.ATTACHMENTS]: false,
      },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: 'medium',
    },
  },
  Finding: {
    path: PAGE_PATHS.FINDINGS,
    entityTypeId: 'Finding',
    projectionName: 'uber_findings',
    pageTitle: 'Findings',
    appPath: APP_PATHS.VULNERABILITIES,
    app: 'Vulnerabilities',
    viewScreenType: ScreenType.FindingsView,
    labelsV2: FilterTypesLabelV2,
    searchFields: ['finding.title', 'finding.cve_id', 'component.name', 'component._key', 'asset.os'],
    defaultSavedView: 'Active Critical and High Findings',
    defaultFilters: {
      'finding.source_names': [],
      'finding.type': [],
      'finding.severity': [],
    },
    searchContextKey: SearchContextKeys.Findings,
    extraFilters: ["finding.state != 'ARCHIVED'"],
    extraRelationGroup: '*',
    titleFieldName: 'title',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    actions: {
      grid: { [ACTIONS_MAP.UPDATE]: true, [ACTIONS_MAP.MERGE]: true },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: false,
      tableSize: 'medium',
    },
  },
  Factor: {
    path: PAGE_PATHS.FACTOR,
    entityTypeId: 'Factor',
    projectionName: 'uber_factors',
    pageTitle: 'Factors',
    isMultiSelect: false,
    appPath: APP_PATHS.RISK360,
    app: 'Risk360',
    labelsV2: FilterTypesLabelV2, //--------
    searchFields: ['factor.name', 'factor.category_name'],
    defaultFilters: {
      'factor.name': [],
      'factor.category_name': [],
      'factor.enabled': [],
    },
    searchContextKey: SearchContextKeys.Factor,
    extraFilters: [],
    rowGroupByField: row => row?.groupId,
    hideVisualInvestigator: true,
    extraRelationGroup: '*',
    titleFieldName: 'name',
    defaultSortBy,
    hideCommentsTab: true,
    hideActivityTab: true,
    hideGroupBy: true,
    actions: {
      grid: { [ACTIONS_MAP.UPDATE]: false, [ACTIONS_MAP.MERGE]: false },
      panel: { [ACTIONS_MAP.ATTACHMENTS]: false },
    },
    styles: {
      isTransparentPageBackground: true,
    },
  },
};

export const mapAliasesAndMetrics = {
  severityScore: {
    alias: 'severityScore',
    metric: 'max_risk_granular',
  },
  totalFindings: {
    alias: 'totalFindings',
    metric: 'active_findings_granular',
  },
};

export const mapAliasesAndDims = {
  [entityViewConfig.Incident.entityTypeId]: {
    sla: {
      alias: 'sla',
      dim: 'incident.sla',
    },
  },
  [entityViewConfig.Ticket.entityTypeId]: {
    sla: {
      alias: 'sla',
      dim: 'ticket.sla',
    },
    remediation: {
      alias: 'remediation',
      dim: '',
      filterFromClickthrough: true,
    },
  },
};
