import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';

const FactorRules = lazy(() => import('../views/FactorRules/FactorRules'));
const EditFactorRules = lazy(() => import('../views/FactorRules/EditFactorRules'));

const FactorLogicRoute = () => {
  const {
    userPermissions: { hasAllowedPermission, isAvalorAdmin },
  } = useAvContext();
  const path = PAGE_PATHS.FACTOR_RULES;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || isAvalorAdmin}>
            <FactorRules />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE }) || isAvalorAdmin}>
            <EditFactorRules />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute
            isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE }) || isAvalorAdmin}>
            <EditFactorRules />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default FactorLogicRoute;
