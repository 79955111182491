import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { FeatureFlags, PAGE_PATHS, Permission } from '../types';

const AccountUsage = lazy(() => import('../views/AccountUsage/AccountUsage'));

const AccountUsageRoute = () => {
  const {
    isBackOfficeAvailable,
    userPermissions: { hasAllowedPermission },
    featureFlags,
  } = useAvContext();
  return (
    isBackOfficeAvailable &&
    featureFlags[FeatureFlags.accountUsagePage] && (
      <Route
        path={PAGE_PATHS.ACCOUNT_USAGE}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: PAGE_PATHS.ACCOUNT_USAGE, permission: Permission.READ })}>
            <AccountUsage />
          </ProtectedRoute>
        }
      />
    )
  );
};

export default AccountUsageRoute;
