import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { useAvContext } from '../../context/AvContextProvider';
import { AccountEntities } from '../../context/context.type';
import { FeatureFlags } from '../../types';

const SETTING_FOR_FIELD = gql`
  query ($modelFieldName: String) {
    getSettingsForSPFField(modelFieldName: $modelFieldName) {
      type
      spfFieldsSynced
      spfFields
    }
  }
`;

const SETTING_FOR_FIELD_V2 = gql`
  query ($modelFieldName: String) {
    getSettingsForSPFFieldV2(modelFieldName: $modelFieldName) {
      type
      spfFieldsSynced
      spfFields
    }
  }
`;

const SETTING_FOR_TYPE = gql`
  query ($settingsType: String) {
    getSettingsForType(settingsType: $settingsType) {
      spfFields
      spfFieldsSynced
    }
  }
`;

const SETTING_FOR_TYPE_V2 = gql`
  query ($settingsType: String) {
    getSettingsForTypeV2(settingsType: $settingsType) {
      spfFields
      spfFieldsSynced
    }
  }
`;

export const useIsFieldSyncedToSetting = modelFieldName => {
  const { api, featureFlags } = useAvContext();
  const isV2 = featureFlags[FeatureFlags.NewAppManagementQueries];
  return useQuery({
    queryKey: [modelFieldName, 'field-sync-to-setting'],
    queryFn: () => api(isV2 ? SETTING_FOR_FIELD_V2 : SETTING_FOR_FIELD, { options: { modelFieldName } }),
    gcTime: 0,
    enabled: !!modelFieldName,
    select: ({ data }) => (isV2 ? data.getSettingsForSPFFieldV2 : data.getSettingsForSPFField),
  });
};

export const useIsSettingSyncedToField = (settingsType: SettingType) => {
  const { api, featureFlags } = useAvContext();
  const isV2 = featureFlags[FeatureFlags.NewAppManagementQueries];
  return useQuery({
    queryKey: [settingsType, 'settings-sync-to-field'],
    queryFn: () =>
      api(isV2 ? SETTING_FOR_TYPE_V2 : SETTING_FOR_TYPE, { options: { settingsType } }).then(({ data }) =>
        isV2 ? data.getSettingsForTypeV2.spfFieldsSynced : data.getSettingsForType.spfFieldsSynced
      ),
    gcTime: 0,
  });
};

const GET_LIST = gql`
  query {
    aggProjections {
      meta {
        id {
          name
          builtIn
        }
      }
      settings
    }
  }
`;

export const useProjectionSettings = () => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['aggProjections'],
    queryFn: () =>
      api(GET_LIST).then(
        ({ data }) =>
          data.aggProjections?.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.meta.id.name]: curr,
            }),
            {}
          )
      ),
  });
};

export const useSeveritySettings = () => {
  const { data } = useProjectionSettings();
  return data?.uber_findings.settings.severities?.filter(s => s.label !== 'NONE')?.reverse() || [];
};

export enum SettingType {
  GroupingRules = 'GROUPING_RULES',
  SeveritySLA = 'SEVERITY_AND_SLA',
  Score = 'SCORING',
  GroupingRulesAlerts = 'GROUPING_RULES_ALERTS',
  SeveritySLAAlerts = 'SEVERITY_AND_SLA_ALERTS',
  ScoreAlerts = 'SCORING_ALERTS',
  UnificationRules = 'UNIFICATION_RULES',
}

export const SettingsName = {
  [SettingType.Score]: 'Score Settings',
  [SettingType.SeveritySLA]: 'Severity & SLA',
  [SettingType.GroupingRules]: 'Grouping Rules',
  [SettingType.UnificationRules]: 'Data Unification',
  [SettingType.ScoreAlerts]: 'Score Settings',
  [SettingType.SeveritySLAAlerts]: 'Severity & SLA',
  [SettingType.GroupingRulesAlerts]: 'Grouping Rules',
};

export const getRuleFilterOptions = (aggProjs: AccountEntities, projectionName: string, featureFlags) =>
  aggProjs[aggProjs[projectionName].aggregates.name].fieldList[featureFlags[FeatureFlags.GroupingRulesV2] ? 'EVAL' : 'SPF'].filter(
    f => !f.visibilityConfig.config.hidden
  );
