import { getIfValidStringifiedObject } from '../../utils/Utils';
import { GroupHeaderRowType } from './types';
import { getComparator } from './Utils';

const getRows = (rows, order, orderBy, headers, page, rowsPerPage, isServerRows, filters, hasHeaderFilters, rowGroupByField?) => {
  const headTypeMap =
    hasHeaderFilters &&
    headers.reduce((acc, header) => {
      acc[header.id] = header.type;
      return acc;
    }, {});
  const filterKeys = Object.keys(headTypeMap);
  const filteredRows = hasHeaderFilters
    ? rows.filter(r =>
        filterKeys.every(k => {
          const noFilter = !filters[k]?.length;
          const rowValue = r[k];
          const isArray = Array.isArray(rowValue);
          const isInList = isArray && rowValue.some(v => filters[k]?.includes(v));
          const isBoolField = headTypeMap[k] === 'bool';
          const isTrue = isBoolField && filters[k]?.[0] === 'true' && rowValue;
          const isFalse = isBoolField && filters[k]?.[0] === 'false' && !rowValue;
          const conditionFilter = getIfValidStringifiedObject(filters[k]);

          if (conditionFilter) {
            const rootOperator = Object.keys(conditionFilter)[0];
            const { value, operator } = conditionFilter[rootOperator][0];
            const lowerCasedValue = value?.toLowerCase();
            const strRowValue = `${rowValue}`;
            if (operator === 'contains') {
              return strRowValue.toLowerCase().includes(lowerCasedValue);
            }
            if (operator === 'notContains') {
              return !strRowValue.toLowerCase().includes(lowerCasedValue);
            }
            if (operator === 'starts') {
              return strRowValue.toLowerCase().startsWith(lowerCasedValue);
            }
            if (operator === 'ends') {
              return strRowValue.toLowerCase().endsWith(lowerCasedValue);
            }
            if (operator === 'equals') {
              return strRowValue.toLowerCase() === lowerCasedValue;
            }
            if (operator === 'notEqual') {
              return strRowValue.toLowerCase() !== lowerCasedValue;
            }
            if (operator === 'empty') {
              return !rowValue;
            }
            if (operator === 'notEmpty') {
              return Boolean(rowValue);
            }
          }
          return noFilter || isTrue || isFalse || isInList || filters[k].includes(r[k]) || r.isGroupHeaderRow === GroupHeaderRowType.GROUP;
        })
      )
    : rows;
  const comparator = getComparator(order, headers.find(({ id }) => id === orderBy)?.getValue || (row => row[orderBy]));
  const groupComparator =
    rowGroupByField &&
    ((a, b) =>
      getComparator(order, rowGroupByField)(a, b) ||
      (a.isGroupHeaderRow === GroupHeaderRowType.GROUP ? -1 : b.isGroupHeaderRow === GroupHeaderRowType.GROUP ? 1 : 0));
  const orderedRows = orderBy
    ? filteredRows.slice().sort(rowGroupByField ? (a, b) => groupComparator(a, b) || comparator(a, b) : comparator)
    : rowGroupByField
      ? filteredRows.slice().sort((a, b) => groupComparator(a, b))
      : filteredRows;
  return rowsPerPage === null || isServerRows ? orderedRows : orderedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};

export default getRows;
