import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';

const GET_STATIC_LISTS = gql`
  query getAllDataSourcesTypes {
    getAllDataSourcesTypes {
      type
      name
      category
      iconUrl
      suppressionFilter
      scheduleFrequency {
        incrementalCron
        fullCron
        supportedSchedules
      }
    }
    getDataSourceDetailsByAccountId {
      mappedSourceName
      iconUrl
    }
    getAllIntegrationTypes {
      type
      name
      category
      supports {
        create
        sync
        webhook
        schema
        comments
        attachments
      }
    }
  }
`;

const convertArrToObj = (obj, item) => ({ ...obj, [item.type]: item });
const convertArrToObjByName = (obj, item) => ({ ...obj, [item.name]: item });

const categorizeSourceOptions = (sourceOptions, connected) => {
  const flatTypes = new Set(connected.map(({ mappedSourceName }) => mappedSourceName));
  return sourceOptions
    .map(opt => ({ ...opt, group: flatTypes.has(opt.name) ? 'Connected' : 'Not Connected' }))
    .sort((a, b) => -b.group.localeCompare(a.group));
};

export const cachedStatic = {
  sourcesObj: { sourcesMap: {}, sourcesMapByName: {}, sourceOptions: [] },
  destinationsObj: { destinationsMap: {}, destinationsOptions: [] },
};

export const useTypeNameMap = (accountId, accessToken, api) =>
  useQuery({
    queryKey: ['source-type-list'],
    queryFn: () =>
      api(GET_STATIC_LISTS).then(QueryData => {
        const data = QueryData?.data;
        if (!data) {
          return {};
        }
        const sources = [
          ...data.getAllDataSourcesTypes,
          ...data.getDataSourceDetailsByAccountId
            .filter(({ mappedSourceName }) => !data.getAllDataSourcesTypes.find(({ name }) => name === mappedSourceName))
            .map(({ mappedSourceName: name, iconUrl }) => ({ type: name, name, iconUrl })),
        ];
        return {
          ...cachedStatic,
          sourcesObj: {
            sourcesMap: sources.reduce(convertArrToObj, {}),
            sourcesMapByName: sources.reduce(convertArrToObjByName, {}),
            sourceOptions: categorizeSourceOptions(sources, data.getDataSourceDetailsByAccountId),
          },
          destinationsObj: {
            destinationsMap: data.getAllIntegrationTypes.reduce(convertArrToObj, {}),
            destinationsOptions: data.getAllIntegrationTypes,
          },
        };
      }),
    enabled: !!accessToken && !!accountId,

    retry: 10,
    retryDelay: 1000,
  });
