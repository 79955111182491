// eslint-disable-next-line import/prefer-default-export
export enum ScreenType {
  Tickets = 'VULNS_TICKETS',
  Incidents = 'INCIDENT_TICKETS',
  Entities = 'ENTITIES_EXPLORER',
  AssetView = 'ASSETS_VIEW',
  RiskDashboard = 'RISK',
  RemediationDashboard = 'REMEDIATION',
  AssetCoverage = 'ASSET_COVERAGE',
  Alerts = 'ALERTS_VIEW',
  PivotVulnerabilities = 'VULNS_PIVOT',
  PivotIncidents = 'INCIDENT_PIVOT',
  FindingsView = 'FINDINGS_VIEW',
}

export type ViewType = {
  id: string;
  name: string;
  favorite: boolean;
  createdByUserId: string;
  createdAt: string;
  defaultView: boolean;
  accountDefaultView: boolean;
  global: boolean;
  publicView: boolean;
  screenType: ScreenType;
  config: any;
};
