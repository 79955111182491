import React, { useState } from 'react';
import { alpha, Box, Button, LinearProgress, Step, StepLabel, Stepper, styled, SxProps } from '@mui/material';
import { dotStyle, noop } from '../utils/Utils';
import AvButtonGroup, { ButtonOption } from './AvButtonGroup';
import { ReactComponent as Checkmark } from '../assets/Checkmark.svg';
import { ReactComponent as Forward } from '../assets/Forward.svg';

type StepType = {
  name?: string;
  hideBack?: boolean;
  nextText?: string;
  actionProps?: {
    label: string;
    options: ButtonOption[];
  };
};

interface AvStepperProps {
  steps?: StepType[];
  onNext?: (v1?, v2?) => void;
  onBack?: () => void;
  onCancel?: () => void;
  isLastStep?: boolean;
  activeStep?: number;
  isLoading?: boolean;
  secondaryAction?: {
    func: (v1?, v2?) => void;
    label: string;
    disabled?: boolean;
  };
  sx?: SxProps;
  disableNext?: boolean;
  sideActionButton?: {
    name: string;
    action: () => void;
    icon: React.ReactElement;
  };
  size?: 'xSmall' | 'small';
}

export const AvStepper: React.FC<AvStepperProps> = ({
  steps = [],
  onNext = noop,
  onBack = noop,
  onCancel,
  isLastStep = false,
  activeStep = 0,
  isLoading = false,
  secondaryAction,
  sx = {},
  disableNext = false,
  sideActionButton,
  size,
}) => (
  <>
    {isLoading && <LinearProgress />}
    <Box sx={wrapperStyle(sx)}>
      {sideActionButton && (
        <Button size={size} variant="outlined" onClick={sideActionButton.action}>
          {sideActionButton.icon} {sideActionButton.name}
        </Button>
      )}
      {steps.length <= 1 || steps[activeStep].hideBack ? undefined : (
        <Button size={size} variant="outlined" onClick={onBack} disabled={activeStep === 0}>
          <Forward style={{ transform: 'rotate(180deg)' }} />
          Back
        </Button>
      )}
      {steps.length > 1 ? (
        <Stepper activeStep={activeStep} sx={{ width: '500px', margin: 'auto' }}>
          {steps.map(({ name }) => (
            <Step key={name}>
              <StepLabel StepIconComponent={AvStepIcon}>{name}</StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <div />
      )}
      <Box sx={rightButtonsStyle}>
        {!steps?.[activeStep]?.hideBack && onCancel && <Button onClick={onCancel}>Cancel</Button>}
        {secondaryAction && (
          <Button size={size} disabled={isLoading || secondaryAction.disabled} variant="outlined" onClick={secondaryAction.func}>
            {secondaryAction.label || 'Test'}
          </Button>
        )}
        {steps.length > 0 && !steps[activeStep]?.actionProps && (
          <Button size={size} disabled={disableNext || isLoading} variant="contained" onClick={onNext}>
            {steps[activeStep]?.nextText ? steps[activeStep]?.nextText : isLastStep ? 'Finish' : 'Next'}
            {steps.length > 1 && <Forward />}
          </Button>
        )}
        {steps[activeStep]?.actionProps && (
          <AvButtonGroup
            // @ts-ignore
            variant="contained"
            size="small"
            options={steps[activeStep].actionProps!.options}
            shouldShowGroups
            disabled={disableNext || isLoading}
          />
        )}
      </Box>
    </Box>
  </>
);

const wrapperStyle = sx => theme => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  background: theme.palette.colors.neutrals[100],
  paddingX: 3,
  paddingY: 2,
  boxShadow: '0px 1px 26px 0px rgba(0, 0, 0, 0.08)',
  zIndex: theme.zIndex.drawer,
  ...sx,
});

const rightButtonsStyle = {
  display: 'flex',
  gap: 2,
};

const AvStepIconRoot = styled('div')(({ theme, state }: any) => ({
  color: theme.palette.colors.neutrals[150],
  display: 'flex',
  alignItems: 'center',
  ...dotStyle(state.active ? alpha(theme.palette.colors.primary[350], 0.3) : 'transparent', 26),
  justifyContent: 'center',
  ...(state.active && {
    color: theme.palette.primary.main,
  }),
  '& .AvStepIcon-completedIcon': {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  '.inner': {
    ...dotStyle(state.active || state.completed ? theme.palette.primary.main : theme.palette.colors.neutrals[500], 16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    svg: {
      width: '85%',
    },
  },
}));

const AvStepIcon = ({ active, completed }: { active: boolean; completed: boolean }) => (
  // @ts-ignore
  <AvStepIconRoot state={{ active, completed }}>
    <div className="inner">{completed && <Checkmark />}</div>
  </AvStepIconRoot>
);

export const useStepper = ({
  steps = [],
  initialStep = 0,
  validateOnNext,
  onBack,
}: {
  steps: StepType[];
  initialStep: number;
  validateOnNext?: () => any;
  onBack?: () => any;
}) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const handleNext = async () => {
    if (!validateOnNext || (await validateOnNext())) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const isLastStep = steps[activeStep].name === steps.at(-1)?.name;
  return { handleNext, handleBack, activeStep, setActiveStep, isLastStep };
};
