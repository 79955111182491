import { useState } from 'react';
import { useAvContext } from '../context/AvContextProvider';
import { ZendeskAPI, ZendeskUser } from '../utils/ZendeskConfig';
import { GetUsersInfo } from './getUsersInfo';

const useZendesk = () => {
  const { user } = useAvContext();
  const { authUser } = user;
  const [isOpen, setIsOpen] = useState(false);
  const { usersInfo: loggedInUser } = GetUsersInfo(user.userId ? [user.userId] : []); // TODO: remove after fixing cognito name AVA-3865
  const openZendeskWidget = () => {
    ZendeskAPI('webWidget', 'show');
    ZendeskAPI('webWidget', 'open');
    setIsOpen(true);
    ZendeskUser({ name: loggedInUser[user.userId], email: authUser?.email || user.email });
    ZendeskAPI('webWidget:on', 'close', () => {
      ZendeskAPI('webWidget', 'hide');
      setIsOpen(false);
    });
  };
  const closeZendeskWidget = () => {
    ZendeskAPI('webWidget', 'close', () => {
      ZendeskAPI('webWidget', 'hide');
      setIsOpen(false);
    });
  };

  return { openZendeskWidget, closeZendeskWidget, isOpen };
};

export default useZendesk;
