import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { AppPathsValue, PAGE_PATHS } from '../types';

const GroupingSets = lazy(() => import('../views/Settings/GroupingRules/GroupingSets'));
const RuleSet = lazy(() => import('../views/Settings/GroupingRules/RuleSet'));

const RulesSetRoute = (projectionName, app: AppPathsValue) => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const path = PAGE_PATHS.RULE_SET;
  const permissionObject = { app, path };
  // TODO: Permissions validate everything here
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <GroupingSets projectionName={projectionName} />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <RuleSet projectionName={projectionName} />
          </ProtectedRoute>
        }
      />
      <Route
        path="create"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <RuleSet projectionName={projectionName} />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/:ruleIndex"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <RuleSet projectionName={projectionName} />
          </ProtectedRoute>
        }
      />
      <Route
        path=":id/:ruleIndex"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission(permissionObject)}>
            <RuleSet projectionName={projectionName} />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default RulesSetRoute;
