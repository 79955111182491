import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, FeatureFlags, PAGE_PATHS } from '../../types';
import { appsOptions } from './appbar.constants';
import { appsSideBar } from './sidebar.constants';

// eslint-disable-next-line import/prefer-default-export
export const useEnabledAppsOptions = () => {
  const {
    accountData,
    user,
    featureFlags,
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  return featureFlags[FeatureFlags.NewJwt]
    ? appsOptions.filter(
        app =>
          accountData.apps?.some(({ name }) => APP_PATHS[name] === app.id) &&
          appsSideBar[app.id]
            .flatMap(r => r.subMenu.flatMap(s => (s.subMenu ? s.subMenu.map(subMenu => ({ ...subMenu, parentPath: s.path })) : s)))
            .filter(({ path }) => ![PAGE_PATHS.CUSTOM_DASHBOARDS, PAGE_PATHS.DATA_LAKE_QUERIES].includes(path))
            .some(({ path }) => hasAllowedPermission({ path, app: app.id }))
      )
    : appsOptions.filter(
        app => accountData.apps?.some(({ name }) => APP_PATHS[name] === app.id) && app.rolesPermission.includes(user.role)
      );
};
