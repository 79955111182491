import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../../components/Permissions';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS, PAGE_PATHS, Permission } from '../../types';
import AccountManagementRoute from '../AccountManagementRoute';
import AccountUsageRoute from '../AccountUsageRoute';
import BackofficeRoute from '../BackofficeRoute';
import EditMyAccountRoute from '../EditMyAccountRoute';
import EditMyUserRoute from '../EditMyUserRoute';
import PermissionsRoute from '../PermissionsRoute';
import RolesOverviewRoute from '../RolesOverviewRoute';
import UserManagementRoute from '../UserManagementRoute';

const QueryPage = lazy(() => import('../../views/BackOffice/QueryPage'));

const Settings = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const queryPath = PAGE_PATHS.QUERY;

  return (
    <Route path={APP_PATHS.SETTINGS}>
      {AccountManagementRoute()}
      {UserManagementRoute(PAGE_PATHS.USER_MANAGEMENT)}
      {UserManagementRoute(PAGE_PATHS.ALL_USERS)}
      {EditMyAccountRoute()}
      {EditMyUserRoute()}
      {BackofficeRoute()}
      {PermissionsRoute()}
      {RolesOverviewRoute()}
      {AccountUsageRoute()}
      {/* TODO: PERMISSIONS */}
      <Route
        path={queryPath}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: queryPath, permission: Permission.UPDATE })}>
            <QueryPage />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};
export default Settings;
