import { DateCondition, Filter } from '../../../types/filter.types';

export enum SortDir {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface Field {
  name: string;
  alias?: string;
}

export interface Select {
  dims: Field[];
  metrics: Field[];
}

export interface TopResults {
  size?: number;
  groupOthers: boolean;
  offset?: number;
}

export interface SortBy {
  name: string;
  dir: SortDir;
}

export interface ProjectionID {
  name: string;
  builtIn: boolean;
}

export interface DwQueryRequest {
  select: Select;
  filter?: Filter;
  groupBy?: string[];
  sorting: SortBy[];
  top?: TopResults;
  projectionId: ProjectionID;
  distinct?: boolean;
  timeRange?: DateCondition;
  metricsFilterThreshold?: number;
}

// Example usage (change here every time so it's clear, include optional vars:
export const dwQuery: DwQueryRequest = {
  select: {
    dims: [{ name: 'dim1' }, { name: 'dim2', alias: 'alias2' }],
    metrics: [{ name: 'meas1' }, { name: 'meas2', alias: 'alias4' }],
  },
  groupBy: ['group1', 'group2'],
  sorting: [
    { name: 'field1', dir: SortDir.ASC },
    { name: 'field2', dir: SortDir.DESC },
  ],
  top: {
    size: 10,
    groupOthers: true,
    offset: 0,
  },
  projectionId: {
    name: 'tickets',
    builtIn: false,
  },
  distinct: false,
};

export const dwQueryDefault: DwQueryRequest = {
  select: {
    dims: [],
    metrics: [],
  },
  groupBy: [],
  sorting: [],
  top: {
    size: 100,
    groupOthers: false,
  },
  projectionId: {
    name: 'tickets',
    builtIn: true,
  },
  distinct: true,
  metricsFilterThreshold: 0,
};

export const getDWQueryDefault = (): DwQueryRequest => structuredClone(dwQueryDefault);

export const groupOthersSizeMax = 20;

export const interactiveQueryMaxRowsSize = 40_000;
export type QueryResponse = { data?: any; totals?: Record<string, number>; totalRowCount?: number };
