import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';

const EditAccountManagement = lazy(() => import('../views/AccountManagement/EditAccountManagement'));

const EditMyAccountRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const path = PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT;
  return (
    <Route
      path={PAGE_PATHS.EDIT_MY_ACCOUNT_MANAGEMENT}
      element={
        <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
          <EditAccountManagement isMyAccount />
        </ProtectedRoute>
      }
    />
  );
};

export default EditMyAccountRoute;
