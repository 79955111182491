import React, { useState } from 'react';
import { Avatar, Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useAvContext } from '../../context/AvContextProvider';
import { APP_PATHS } from '../../types';
import { getBranchPrefix } from '../../utils/router.utils';
import { noop } from '../../utils/Utils';
import { flex } from '../AvThemeProvider';
import { ReactComponent as Arrow } from '../../assets/Arrow Down.svg';
import { ReactComponent as SortDown } from '../../assets/Sort Down.svg';

const arrowIcon = <Arrow style={{ transform: `rotate(90deg)` }} />;
const popoverStyle = theme => ({
  pointerEvents: 'none',
  '.MuiMenu-paper': {
    pointerEvents: 'auto',
    minWidth: 250,
  },
  '.MuiMenuItem-root': {
    paddingLeft: '8px',
    minHeight: 40,
    color: theme.palette.colors.neutrals[800],
  },
});
interface SwitchAccountMenuProps {
  closeParent: any;
  setFilteredAppsBarItems: (arg0: any[]) => void;
}
const SwitchAccountMenu: React.FC<SwitchAccountMenuProps> = ({ closeParent = noop, setFilteredAppsBarItems }) => {
  const { setAccountId, setSelectedApp, accountData, user } = useAvContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const client = useQueryClient();
  const [open, setOpen] = useState(false);
  const [sortAsc, setSortAsc] = useState(true);
  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const switchAcc = id => {
    if (id === user.accountId) {
      return;
    }
    setAccountId(id);
    handleClose();
    client.clear();
    closeParent();
    setFilteredAppsBarItems([]);
    setSelectedApp(APP_PATHS[accountData.apps[0]?.name] || '');
  };

  const accounts = user.accounts?.sort((a, b) =>
    sortAsc ? a.accountName.localeCompare(b.accountName) : b.accountName.localeCompare(a.accountName)
  );

  if (!accounts || accounts.length === 1) {
    return null;
  }

  return (
    <MenuItem onClick={e => e.stopPropagation()} onMouseEnter={handleClick} onMouseLeave={handleClose}>
      <>
        {arrowIcon}
        <Typography
          aria-label="switch account"
          aria-controls={open ? 'accounts-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true">
          Switch account
        </Typography>
      </>

      <Menu
        id="accounts-menu"
        MenuListProps={{ 'aria-labelledby': 'long-button' }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        open={open}
        sx={popoverStyle}
        PaperProps={{ onMouseEnter: () => setOpen(true), onMouseLeave: handleClose }}>
        {accounts.length > 4 ? (
          <Box sx={{ ...flex.justifyEnd, px: 1 }}>
            <Button onClick={() => setSortAsc(!sortAsc)} sx={{ gap: 0 }}>
              <SortDown style={{ height: 18, width: 18, ...(sortAsc && { transform: 'rotate(180deg)' }) }} />
              A-Z
            </Button>
          </Box>
        ) : null}
        {accounts.map(({ accountId: id, accountName: name }) => (
          <Link key={id} to={`/${getBranchPrefix() ? `${getBranchPrefix()}/` : ''}${id}/`}>
            <MenuItem selected={id === user.accountId} onClick={() => switchAcc(id)}>
              <Avatar src={`/account-logo/${id}.png`} alt={name} sx={{ width: 30, height: 30 }} />
              {name}
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </MenuItem>
  );
};

export default SwitchAccountMenu;
