import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import { NotFound } from '../components/ErrorBoundary';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { AppPathsValue, PAGE_PATHS } from '../types';
import { entityViewConfig } from '../utils/mapping';
import { GroupingRuleSetTypes } from '../views/Settings/GroupingRules/types';
import RulesSetRoute from './RulesSetRoute';

const UiConfigSettings = lazy(() => import('../views/Settings/UiConfigSettings/UiConfigSettings'));
const AuditLogsUiConfig = lazy(() => import('../views/Settings/UiConfigSettings/AuditLogsUiConfig'));
const ProjStatuses = lazy(() => import('../views/Settings/TicketStatuses/ProjStatuses'));

const DetectionSettings = (app: AppPathsValue) => {
  const {
    userPermissions: { hasAllowedPermission, isAvalorAdmin },
  } = useAvContext();

  const getHasAllowedPermission = path => hasAllowedPermission({ path, app });
  return (
    <>
      <Route path={PAGE_PATHS.SETTINGS}>
        <Route path="" element={<NotFound />} />
        <Route
          path={PAGE_PATHS.UI_CONFIG}
          element={
            <ProtectedRoute isAllowed={isAvalorAdmin}>
              <UiConfigSettings projectionName={entityViewConfig.Incident.projectionName} />
            </ProtectedRoute>
          }
        />
        <Route
          path={PAGE_PATHS.TICKETS_STATUSES}
          element={
            <ProtectedRoute isAllowed={getHasAllowedPermission(PAGE_PATHS.TICKETS_STATUSES)}>
              <ProjStatuses projName="incidents" />
            </ProtectedRoute>
          }
        />
        {RulesSetRoute(GroupingRuleSetTypes.Incident, app)}
      </Route>
      <Route
        path={`${PAGE_PATHS.UI_CONFIG}/audit/:path`}
        element={
          <ProtectedRoute isAllowed={isAvalorAdmin}>
            <AuditLogsUiConfig />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default DetectionSettings;
