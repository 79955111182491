import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';

const BackofficeActions = lazy(() => import('../views/BackOffice/BackofficeActions'));

const BackofficeRoute = () => {
  const {
    isBackOfficeAvailable,
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const path = PAGE_PATHS.BACKOFFICE_ACTIONS;
  return (
    isBackOfficeAvailable && (
      <Route
        path={path}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <BackofficeActions />
          </ProtectedRoute>
        }
      />
    )
  );
};

export default BackofficeRoute;
