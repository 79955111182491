import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';

const DataLakeQueries = lazy(() => import('../views/DataLakeQueries/DataLakeQueries'));
const EditDataLakeQueries = lazy(() => import('../views/DataLakeQueries/EditDataLakeQueries'));
const TestQuery = lazy(() => import('../views/DataLakeQueries/TestQuery'));

const DataLakeQueriesRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const path = PAGE_PATHS.DATA_LAKE_QUERIES;
  return (
    <>
      <Route path={path}>
        <Route
          path=""
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
              <DataLakeQueries />
            </ProtectedRoute>
          }
        />
        <Route
          path="create/"
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
              <EditDataLakeQueries />
            </ProtectedRoute>
          }
        />
        <Route
          path="edit/:id"
          element={
            <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
              <EditDataLakeQueries />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path={PAGE_PATHS.TEST_QUERY}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <TestQuery />
          </ProtectedRoute>
        }
      />
    </>
  );
};

export default DataLakeQueriesRoute;
