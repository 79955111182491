import { gql } from '@apollo/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useAvContext } from '../../context/AvContextProvider';
import { PermissionEntitiesNames } from '../../types';
import { getNetworkErrorCode } from '../../utils/Utils';
import { Integration, MappingGroup } from './types';

const GET_LIST = gql`
  query {
    findIntegrationInstanceByAccountId {
      id
      name
      integrationType
      active
      category
    }
  }
`;

export const GET_BY_ID = gql`
  query findIntegrationInstanceById($id: String!) {
    findIntegrationInstanceById(id: $id) {
      id
      name
      active
      integrationType
      redactedConfig
      schema
      category
      mapping {
        id
        createMapping
        syncComments
        createMappingEnabled
        syncMapping
        syncMappingEnabled
        webhookMapping
        webhookMappingEnabled
      }
      matchingCondition
      targetMatchingType
      category
      authenticationSourceId
      projectionName
      gatewayId
    }
  }
`;

const UPDATE_INTEGRATION = gql`
  mutation ($integrationInstanceInput: IntegrationInstanceInput!, $skipValidation: Boolean) {
    updateIntegrationInstance(integrationInstanceInput: $integrationInstanceInput, skipValidation: $skipValidation) {
      name
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  mutation ($ids: [String]!) {
    deleteIntegrations(ids: $ids)
  }
`;

const DUPLICATE_INTEGRATION = gql`
  mutation ($integrationInstanceId: String!) {
    duplicateIntegration(integrationInstanceId: $integrationInstanceId)
  }
`;

const TEST_CREATE_MAPPING = gql`
  query ($integrationInstanceId: String!, $mapping: MappingMetadata, $entityKey: String!) {
    testCreateIntegrationMapping(integrationInstanceId: $integrationInstanceId, createMapping: $mapping, entityKey: $entityKey)
  }
`;

export const useNotificationTargetsList = (options?) => {
  const {
    api,
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  return useQuery<Integration[]>({
    queryKey: ['NotificationTargets'],
    queryFn: () => api(GET_LIST, { onSuccess: ({ data }) => data.findIntegrationInstanceByAccountId }),
    ...options,
    enabled: hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.INTEGRATION }),
  });
};

export const useGetIntegrationById = id => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['integration', id],
    queryFn: (): Integration =>
      api(GET_BY_ID, { options: { id } }).then(({ data }) => ({
        ...data?.findIntegrationInstanceById,
        mapping: {
          ...data.findIntegrationInstanceById.mapping,
          createMapping: {
            ...data.findIntegrationInstanceById.mapping.createMapping,
            fieldMapping: data.findIntegrationInstanceById.mapping.createMapping.fieldMapping || [],
          },
          syncMapping: {
            ...data.findIntegrationInstanceById.mapping.syncMapping,
            fieldMapping: data.findIntegrationInstanceById.mapping.syncMapping.fieldMapping || [],
          },
          webhookMapping: {
            ...data.findIntegrationInstanceById.mapping.webhookMapping,
            fieldMapping: data.findIntegrationInstanceById.mapping.webhookMapping.fieldMapping || [],
          },
        },
      })),
    gcTime: 0,
  });
};

export const useUpdateIntegration = onSuccess => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: (integration: Integration, skipValidation = true): any => {
      const integrationInstanceInput = {
        id: integration.id,
        name: integration.name,
        active: integration.active,
        integrationType: integration.integrationType,

        config: integration.redactedConfig,
        category: integration.category,
        mapping: integration.mapping,
        targetMatchingType: integration.targetMatchingType,
        matchingCondition: integration.matchingCondition,
        authenticationSourceId: integration.authenticationSourceId,
        gatewayId: integration.gatewayId,
      };
      return api(UPDATE_INTEGRATION, { options: { integrationInstanceInput, skipValidation }, muteErrors: true })
        .then(onSuccess)
        .catch(error => {
          if (getNetworkErrorCode(error) === 400) {
            enqueueSnackbar(error.message, { variant: 'error' });
          } else {
            throw error;
          }
        });
    },
  });
};
export const useDuplicationNotificationTarget = () => {
  const { api } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation({
    mutationFn: notificationTargetId =>
      api(DUPLICATE_INTEGRATION, {
        options: { integrationInstanceId: notificationTargetId },
        onSuccess: ({ data }) => {
          enqueueSnackbar('Duplicated Successfully', { variant: 'success' });
          return data.duplicateIntegration;
        },
      }),
  });
};

export const useTestCreateMapping = () => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({
      integrationInstanceId,
      mapping,
      entityKey,
    }: {
      integrationInstanceId: string;
      mapping: MappingGroup;
      entityKey: string;
    }) =>
      api(TEST_CREATE_MAPPING, { options: { integrationInstanceId, mapping, entityKey } }).then(
        ({ data }) => data.testCreateIntegrationMapping
      ),
  });
};
