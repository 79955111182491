import React, { useState } from 'react';
import { Amplify } from 'aws-amplify';
import { shouldSkipUILogin } from '../utils/auth.utils';
import { ProvideAuth } from './amplifyProvider';
import Login, { AuthConfiguration } from './Login';

interface AuthProviderWithHistoryProps {
  children: React.ReactNode;
}

export default function AuthProviderWithHistory({ children }: AuthProviderWithHistoryProps) {
  const [authConfig, setAuthConfig] = useState<AuthConfiguration | null>(JSON.parse(localStorage.getItem('authConfig') || 'null'));

  if (shouldSkipUILogin()) {
    return <ProvideAuth>{children}</ProvideAuth>;
  }
  if (!authConfig) {
    return <Login setAuthConfig={setAuthConfig} />;
  }

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: authConfig.userPoolWebClientId,
        userPoolId: authConfig.userPoolId,
        loginWith: {
          oauth: {
            domain: authConfig.userPoolDomain,
            scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [`${window.location.origin}`],
            redirectSignOut: [`${window.location.origin}`],
            responseType: 'code',
          },
        },
      },
    },
  });
  return <ProvideAuth>{children}</ProvideAuth>;
}
