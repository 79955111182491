import React, { useContext, useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { RefetchOptions, RefetchQueryFilters, useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { appsOptions } from '../components/newNavigation/appbar.constants';
import { useAccessToken, useAuthentications, useLogout } from '../hooks/auth';
import useHandleError from '../hooks/useHandleError';
import { useTypeNameMap } from '../hooks/UseTypeNameMap';
import { APP_PATHS, FeatureFlags, PAGE_PATHS, Permission, ROLES } from '../types';
import { setAccountIdLocalStorage, useLogoutWhenUserIsIdle } from '../utils/auth.utils';
import { entityViewConfig } from '../utils/mapping';
import {
  getAccountFromLS,
  getAccountIdFromPathName,
  getAppIdFromPathName,
  getBranchPrefix,
  getPathNameByAccount,
} from '../utils/router.utils';
import { initializePendo, shouldRecordSession } from '../utils/rum.utils';
import useApi from '../utils/useApi';
import { capitalizeSentence } from '../utils/Utils';
import { GENERAL_ENTITY } from '../views/Sources/Mapping/mapping.types';
import { usePermissions } from './AvContext.hooks';
import { AccountType, AvPermissionsType } from './AvContext.types';
import { useNetworkStatus } from './AvQueryProvider';
import { AccountEntities, DescriptorType } from './context.type';
import { FieldNameDescriptor } from './utils';

export const AvContext = React.createContext<AvContextType>({
  UIConfig: undefined,
  accessToken: '',
  accountData: { id: '', name: '', apps: [], logo: null, accountType: AccountType.POC },
  accountId: '',
  selectedApp: '',
  setSelectedApp(): void {},
  api: undefined,
  demoMode: false,
  featureFlags: undefined,
  accountEntities: {} as AvContextType['accountEntities'],
  getPathName: () => '',
  isAuthenticated: undefined,
  isLoading: false,
  measurements: [],
  loginWithRedirect: undefined,
  logout(): void {},
  refetchUIConfig(): void {},
  setAccountId(): void {},
  typeNameMap: undefined,
  user: {
    accountId: '',
    accounts: [{ accountId: '', accountName: '', roleId: ROLES.VIEWER, userRoleId: '' }],
    authUser: { name: '', email: '' },
    permissions: [],
    role: ROLES.VIEWER,
    userId: '',
    favoriteApps: [],
  },
  refetchUser: undefined,
  refetchAdditionalData: undefined,
  isBackOfficeAvailable: false,
  userPermissions: {
    hasAllowedPermission: () => false,
    hasAllowedPermissionToResource: () => false,
    hasAllowedEditResourcePermission: () => false,
    allowedPermissionsToPath: () => ({}),
    allowedPermissions: {},
    isAvalorAdmin: false,
  },
});

const getUserRole = user => user.accounts.find(({ accountId }) => accountId === user.accountId).roleId;

const useUser = (token, accountId, setAccountId, authUser, api, demoMode) => {
  const nav = useNavigate();
  return useQuery({
    queryKey: ['user'],
    queryFn: () =>
      api(`user/profile${accountId ? `?accountId=${accountId}` : ''}`, {
        excludeAccountIdHeader: true,
        isJson: false,
        muteErrors: true,
      })
        .then(user => {
          const newUser = {
            ...user,
            ...(demoMode ? { accountId, authUser: { email: 'demoMode@avalor.com' } } : { authUser }),
            role: getUserRole(user),
          };
          setAccountId(newUser.accountId);
          if (!newUser.isSuccessfullyLoggedIn) {
            api(SET_SUCCESS_LOGIN, {
              options: { userId: newUser.userId, headers: { accountId: newUser.accountId } },
              excludeAccountIdHeader: true,
            });
          }
          return newUser;
        })
        .catch(e => {
          if (e?.status === 404) {
            console.warn(e);
            nav('error-process-request');
          } else {
            throw new Error(`Failed to fetch user data: ${e.status} ${e.message}`);
          }
        }),
    enabled: !!token && !!accountId,
  });
};

const useUserAdditionalInfo = (accessToken, authUser, accountId, api) =>
  useQuery({
    queryKey: ['userAdditionalInfo', JSON.stringify(authUser)],
    queryFn: () =>
      api(GET_USER_ADDITIONAL_DATA).then(({ data }) => ({
        ...authUser,
        ...data?.getUserAdditionalData,
        role: Number(authUser.roleId),
      })),
    enabled: !!accessToken && !!accountId && !!authUser.userId,
  });

const useAccount = ({ accountId, accessToken, api, demoMode, defaultAccount }) => {
  const nav = useNavigate();
  return useQuery<AccountDto>({
    queryKey: ['account'],
    queryFn: () =>
      api(GET_ACCOUNT, {
        options: { id: accountId },
      }).then(({ data, errors }) => {
        if (errors) {
          console.error(errors[0]?.message);
          if (errors?.[0].extensions?.status === 404) {
            nav('error-process-request');
            return defaultAccount;
          }
          throw new Error('Failed to fetch account data', errors[0]?.message);
        }
        if (shouldRecordSession) {
          datadogRum.setUser({
            ...datadogRum.getUser(),
            accountName: data?.findStartupAccountById?.name,
            accountType: data?.findStartupAccountById?.accountType,
            accountCreated: data?.findStartupAccountById?.createdAt,
          });
          initializePendo(datadogRum.getUser() as any);
        }
        return data?.findStartupAccountById;
      }),
    enabled: demoMode || Boolean(accountId && accessToken),
    retry: 10,
    retryDelay: 1000,
  });
};
const useFeatureFlags = (accountId, accessToken, api, onSuccess = d => d) =>
  useQuery({
    queryKey: ['featureFlags'],
    queryFn: () => api('feature-flags', { isJson: false, onError: () => false, onSuccess }),
    enabled: !!accessToken && !!accountId,
    retry: 10,
    retryDelay: 1000,
  });

const flatNestedList = fields =>
  fields.flatMap(field =>
    field.type.fields && !field.repeated
      ? flatNestedList(
          field.type.fields.map(f => ({
            ...f,
            displayName: `${field.displayName || ''} ${f.displayName}`.trim(),
            name: `${field.name}.${f.name}`,
          }))
        )
      : [field]
  );

// TODO: remove this when BE will return these fields
const systemFieldsToHideOnBulkUpdate = [
  'current_status.timestamp',
  'integration_info.type',
  'integration_info.key',
  'integration_info.notification_target_id',
  'exception_integration_info.key',
  'exception_integration_info.type',
  'exception_integration_info.display_key',
  'exception_integration_info.notification_target_id',
];

const removeSPFFromSelfPath = (projId, sourceProj, usages) => (projId.name === sourceProj.name ? usages.filter(u => u !== 'SPF') : usages);
const addEVALToPathExcludingAggregatedIngress = (aggsMap, sourceProj, projId) =>
  aggsMap[sourceProj.name].name === projId.name && !aggsMap[projId.name] ? [] : ['EVAL'];

const getUsagesWithEval = ({ projId, usages }, sourceProj, aggsMap) =>
  usages.includes('SPF')
    ? [...removeSPFFromSelfPath(projId, sourceProj, usages), ...addEVALToPathExcludingAggregatedIngress(aggsMap, sourceProj, projId)]
    : usages;

const useEntities = (accountId, accessToken, api, featureFlags) => {
  const {
    data = { aggProjs: {}, ingressProjs: {}, fieldTypeMap: {}, fieldMap: {} },
    isLoading,
    refetch,
    error,
  } = useQuery({
    queryKey: ['entities'],
    queryFn: () =>
      api(GET_ENTITIES).then(({ data }) => {
        const entityTypes = data.allEntityTypes.reduce(
          (obj, { id: { name, builtIn }, fields }) => ({
            ...obj,
            [name]: {
              fields: [
                ...data.metadataFields.map(item => ({ ...item, isMetadata: true })),
                ...flatNestedList(fields).map(field =>
                  systemFieldsToHideOnBulkUpdate.includes(field.name)
                    ? { ...field, additionalInfo: { ...field.additionalInfo, isSystemField: true } }
                    : field
                ),
              ],
              name,
              builtIn,
            },
          }),
          {}
        );
        const orderMap = Object.keys(entityTypes).reduce((obj, name, i) => ({ ...obj, [name]: i }), {});

        const ingressProjs = [entityTypes[GENERAL_ENTITY], ...data.ingressProjections]
          .sort((a, b) => orderMap[a.meta?.typeId.name] - orderMap[b.meta?.typeId.name])
          .reduce((obj, projection) => {
            const { builtIn, fields, name } = entityTypes[projection.meta?.typeId.name || GENERAL_ENTITY];
            return {
              ...obj,
              [name]: {
                name,
                builtIn,
                entityTypeId: projection.meta?.typeId,
                projId: projection.meta?.id,
                projDisplayName: name,
                nameMap: fields.reduce((obj, { name, displayName }) => ({ ...obj, [name]: capitalizeSentence(displayName) }), {}),
                fields: fields.map(f => ({ ...f, displayName: capitalizeSentence(f.displayName) })),
              },
            };
          }, {});

        const getFields = projName => target =>
          entityTypes[target.entityTypeId.name].fields.map(field => {
            const fieldVisibilityConfig = data.findFieldVisibilitiesByAccountId.find(dto => {
              const fieldId = `${dto.projectionName}.${dto.fieldName}`;
              return fieldId === `${target.projId.name}.${field.name}`;
            });

            return {
              ...field,
              id: `${projName === target.projId.name ? '' : `${projName}.`}${target.alias}.${field.name}`,
              value: `${target.alias}.${field.name}`,
              ingressValue: `${target.alias}.sources.${field.name}`,
              title: `${target.displayName} ${field.displayName}`,
              group: target.displayName,
              usages: target.usages,
              type: getType(field.type, field.repeated),
              originalType: field.type,
              entityTypeId: target.entityTypeId,
              mainProjId: target.projId,
              visibilityConfig: (featureFlags[FeatureFlags.FieldVisibility] && fieldVisibilityConfig) || { config: { hidden: false } },
            };
          });

        const aggsMap = data.projectionAliasPaths.reduce((obj, { projId, aggregates }) => ({ ...obj, [projId.name]: aggregates }), {});
        const aggProjs = data.projectionAliasPaths
          .sort((a, b) => orderMap[a.entityTypeId.name] - orderMap[b.entityTypeId.name])
          .reduce((obj, { projId, entityTypeId, projDisplayName, aliases: originalAliases, aggregates }) => {
            const aliases = originalAliases.map(item => ({ ...item, usages: getUsagesWithEval(item, projId, aggsMap) }));
            const fields = aliases.flatMap(getFields(projId.name));
            const selfTarget = aliases.find(({ projId: { name } }) => name === projId.name);
            return {
              ...obj,
              [projId.name]: {
                projId,
                projDisplayName,
                entityTypeId,
                aggregates: aggregates || { builtIn: null, name: null },
                pathAlias: selfTarget.alias,
                fields: getFields(projId.name)(selfTarget),
                nameMap: fields.reduce((obj, { name, displayName }) => ({ ...obj, [name]: displayName }), {}),
                typeMap: fields.reduce((obj, { value, type }) => ({ ...obj, [value]: type }), {}),
                fieldList: fields.reduce(
                  (usagesObj, { usages, ...field }) => ({
                    ...usagesObj,
                    ...usages.reduce((obj, usage) => ({ ...obj, [usage]: [...(usagesObj[usage] || []), field] }), {}),
                  }),
                  {}
                ),
              },
            };
          }, {}) as AccountEntities;
        return {
          aggProjs,
          ingressProjs,
          fieldTypeMap: Object.values(aggProjs).reduce((map, { typeMap }) => ({ ...map, ...typeMap }), {}),
          fieldMap: Object.values(aggProjs).reduce(
            (map, { fieldList }) => ({
              ...map,
              ...Object.values(fieldList).reduce(
                (bigMap, list) => ({
                  ...bigMap,
                  ...list.reduce((obj, field) => ({ ...obj, [field.value]: field }), {}),
                }),
                {}
              ),
            }),
            {}
          ),
        };
      }),
    enabled: Boolean(accountId && accessToken && Object.keys(featureFlags).length > 1),
    retry: 10,
    retryDelay: 1000,
  });
  return { data: { ...data, isLoading, refetch }, isLoading, error };
};

export const getType = (type, repeated) => {
  const result = type.name?.includes('Timestamp')
    ? 'DATE'
    : type.fields
      ? 'MESSAGE'
      : DescriptorType[type.kind] || FieldNameDescriptor[type.name] || type.name;
  return repeated ? [result] : result;
};

const useAccountId = () => {
  const location = useLocation();
  const id = getBranchPrefix() ? getAccountFromLS() : getAccountIdFromPathName(location.pathname) || getAccountFromLS();
  return id === 'error-process-request' ? '' : id;
};

export const useMeasurements = ({ accountId, accessToken, api }: { accountId?; accessToken?; api }) =>
  useQuery({
    queryKey: ['measurementList'],
    queryFn: () =>
      api(GET_MEASUREMENT).then(
        ({ data }) =>
          data?.findMeasurementsByAccountId.map(v => {
            const { measurement, ...rest } = v;
            return {
              ...measurement,
              ...rest,
              type: DescriptorType.TYPE_DOUBLE,
            };
          })
      ),
    enabled: !!accountId && !!accessToken,
  });

const useUiConfig = (accessToken, accountId, api) =>
  useQuery({
    queryKey: ['ui_config'],
    queryFn: () => api(GET_UI_CONFIG, { onSuccess: ({ data }) => data.getUIConfigurationByProjection }),
    enabled: !!accessToken && !!accountId,
    retry: 10,
    retryDelay: 1000,
  });

export default function AvContextProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleError = useHandleError();
  const {
    user: authUser,
    isLoading: loadingAuth,
    isAuthenticated,
    getAccessTokenSilently,
    logout: authLogout,
    loginWithRedirect,
  } = useAuthentications();
  const accountId = useAccountId();
  const { isOnline } = useNetworkStatus();
  const {
    isLoading: loadingToken,
    data: accessToken,
    refetch: refetchAccessToken,
  } = useAccessToken(getAccessTokenSilently, isAuthenticated, accountId, isOnline);
  const [searchParams] = useSearchParams();
  const demoMode = !!searchParams.get('demoMode');
  const logout = useLogout(authLogout, loginWithRedirect);
  const [featureFlags, setFeatureFlags] = useState({});
  const api = useApi(demoMode, accountId, logout, refetchAccessToken, featureFlags);
  const selectedAppId = getAppIdFromPathName(location.pathname) || '';
  const [selectedApp, setSelectedApp] = useState(selectedAppId || '');
  const defaultAccount: AccountDto = { id: '', name: '', apps: [], logo: null, accountType: AccountType.POC };
  const {
    data: accountData = defaultAccount,
    refetch: refetchAccountData,
    isLoading: isLoadingAccount,
    error: accountError,
  } = useAccount({ accountId, accessToken, api, demoMode, defaultAccount });

  const supportOldTicketRoute = () => {
    const { search } = window.location;
    const pathname = window.location.pathname?.split('/')?.slice(2)?.join('/');
    const ticketCategory = searchParams.get('ticketCategory');
    const ticketApp = ticketCategory && ticketCategory !== entityViewConfig.Ticket.app ? APP_PATHS.DETECTIONS : APP_PATHS.VULNERABILITIES;
    setSelectedApp(ticketApp);
    navigate(getPathNameByAccount(accountId, ticketApp)(pathname, search));
  };

  useEffect(() => {
    if (selectedAppId) {
      if (selectedAppId === PAGE_PATHS.TICKETS) {
        supportOldTicketRoute();
      } else {
        setSelectedApp(selectedAppId);
      }
    }
  }, [selectedAppId]);

  const setAccountId = id => {
    setAccountIdLocalStorage(id);
    if (location.pathname === '/') {
      navigate(`${getBranchPrefix() ? `${getBranchPrefix()}/` : ''}${id}`);
    }
  };

  const {
    data: user = {},
    isLoading: isLoadingUser,
    refetch: refetchUser,
  } = useUser(accessToken, accountId, setAccountId, authUser, api, demoMode);
  const {
    data: tokenUser = { permissions: '' },
    isLoading: isLoadingTokenUser,
    refetch: refetchAdditionalData,
  } = useUserAdditionalInfo(accessToken, authUser, accountId, api);
  const { isLoading: isLoadingFeatureFlags, error: ffError } = useFeatureFlags(accountId, accessToken, api, setFeatureFlags);
  useLogoutWhenUserIsIdle({ logout, featureFlag: featureFlags[FeatureFlags.IdleTimoutForLogout] });
  const isNewJWT = featureFlags[FeatureFlags.NewJwt];
  const { data: UIConfig = {}, isLoading: isLoadingUIConfig, refetch: refetchUIConfig } = useUiConfig(accessToken, accountId, api);
  const { data: typeNameMap, isLoading: isLoadingSources } = useTypeNameMap(accessToken, accountId, api);
  const { data: measurements } = useMeasurements({ accessToken, accountId, api });
  const { data: accountEntities, isLoading: loadingFields, error: entitiesError } = useEntities(accountId, accessToken, api, featureFlags);
  const userPermissions = usePermissions({ featureFlags, user: isNewJWT ? tokenUser : user });
  const isBackOfficeAvailable = isNewJWT
    ? userPermissions.hasAllowedPermission({ path: PAGE_PATHS.BACKOFFICE_ACTIONS, permission: Permission.UPDATE })
    : user.isBackOfficeAllowed;
  useEffect(() => {
    if (user?.role && user.role === ROLES.PRE_POV) {
      setSelectedApp(APP_PATHS.PLATFORM);
    } else if (accountData.apps.length && !selectedApp && user?.role) {
      const userApps = appsOptions.find(
        ({ rolesPermission, id }) =>
          rolesPermission.includes(user?.role) && accountData.apps.map(({ name }) => APP_PATHS[name]).includes(id)
      );
      setSelectedApp(userApps?.id);
    }
  }, [accountData.apps, user.role]);

  const error = accountError || ffError || entitiesError;
  if (error) {
    handleError(error);
    throw new Error(
      `${
        accountError
          ? 'failed to fetch account data'
          : ffError
            ? 'failed to fetch feature flags'
            : entitiesError
              ? 'Failed to fetch entities'
              : 'contextError'
      } ${error}`
    );
  }

  const value = useMemo(
    () => ({
      accountId,
      setAccountId,
      selectedApp,
      setSelectedApp,
      demoMode,
      accessToken,
      featureFlags,
      logout,
      isLoading:
        loadingToken ||
        isLoadingUser ||
        isLoadingTokenUser ||
        isLoadingFeatureFlags ||
        isLoadingAccount ||
        isLoadingUIConfig ||
        isLoadingSources ||
        (!demoMode && loadingAuth) ||
        loadingFields,
      isAuthenticated: demoMode || isAuthenticated,
      user: isNewJWT ? tokenUser : user,
      refetchUser,
      refetchAdditionalData,
      accountEntities,
      typeNameMap,
      measurements,
      accountData,
      refetchAccountData,
      UIConfig,
      api,
      loginWithRedirect,
      getPathName: getPathNameByAccount(accountId, selectedApp),
      refetchUIConfig,
      isBackOfficeAvailable,
      userPermissions,
    }),
    [
      accountId,
      accessToken,
      featureFlags,
      loadingAuth,
      loadingFields,
      loadingToken,
      isLoadingUser,
      isLoadingAccount,
      accountEntities,
      typeNameMap,
      measurements,
      isLoadingFeatureFlags,
      isLoadingUIConfig,
      isAuthenticated,
      user,
      tokenUser,
      accountData,
      UIConfig,
      selectedApp,
      userPermissions,
    ]
  );

  return <AvContext.Provider value={value}>{children}</AvContext.Provider>;
}
AvContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAvContext = () => useContext(AvContext);

const SET_SUCCESS_LOGIN = gql`
  mutation setUserFirstLogin($userId: String!) {
    setUserFirstLogin(userId: $userId)
  }
`;

const GET_ENTITIES = gql`
  query allEntityTypes {
    ingressProjections {
      meta {
        id {
          name
          builtIn
        }
        typeId {
          name
          builtIn
        }
      }
    }
    allEntityTypes {
      id {
        name
        builtIn
      }
      fields {
        name
        builtIn
        displayName
        type
        repeated
        tags
        additionalInfo {
          hideFromMapping
        }
      }
    }
    metadataFields {
      name
      builtIn
      displayName
      type
      repeated
      tags: tagsList
    }
    projectionAliasPaths {
      projId {
        name
        builtIn
      }
      entityTypeId {
        name
        builtIn
      }
      projDisplayName
      aggregates {
        name
        builtIn
      }
      aliases {
        projId {
          name
          builtIn
        }
        entityTypeId {
          name
          builtIn
        }
        alias
        displayName
        usages
      }
    }
    findFieldVisibilitiesByAccountId {
      id
      projectionName
      builtInProjection
      fieldName
      config
    }
  }
`;

const GET_ACCOUNT = gql`
  query findStartupAccountById($id: String!) {
    findStartupAccountById(id: $id) {
      name
      logo
      accountType
      createdAt
      apps {
        id
        name
      }
    }
  }
`;

const GET_USER_ADDITIONAL_DATA = gql`
  query getUserAdditionalData {
    getUserAdditionalData {
      accounts {
        accountId
        accountName
        roleId
      }
      favoriteApps
    }
  }
`;

export const GET_MEASUREMENT = gql`
  query {
    findMeasurementsByAccountId {
      measurement
      createdByUserId
      updatedByUserId
      id
    }
  }
`;

const GET_UI_CONFIG = gql`
  query ($projectionName: String) {
    getUIConfigurationByProjection(projectionName: $projectionName)
  }
`;

export type AvContextType = {
  accountId: string;
  setAccountId: (id: string) => void;
  selectedApp: string;
  setSelectedApp: React.Dispatch<React.SetStateAction<string>>;
  demoMode: boolean;
  accessToken: string;
  featureFlags: any;
  logout: (redirectBack?: boolean) => void;
  isLoading: boolean;
  isAuthenticated: boolean | undefined;
  user: UserType;
  refetchUser: any;
  refetchAdditionalData: any;
  accountEntities: {
    aggProjs: AccountEntities;
    ingressProjs: AccountEntities;
    fieldTypeMap: Record<string, any>; // deprecated
    fieldMap: Record<string, any>;
    isLoading: boolean;
    refetch: () => void;
  };
  typeNameMap: any;
  accountData: AccountDto;
  UIConfig: any;
  api: any;
  measurements: any[];
  loginWithRedirect;
  getPathName: (path?: string, rest?: string, newSelectedApp?: string) => string;
  refetchUIConfig: (options?: RefetchOptions & RefetchQueryFilters) => void;
  isBackOfficeAvailable: boolean;
  userPermissions: AvPermissionsType;
};

export type AppDto = {
  id: string;
  name: string;
};

type AccountDto = {
  id: string;
  name: string;
  logo: any;
  apps: AppDto[];
  accountType: AccountType;
};
export type UserType = {
  accountId: string;
  accounts: [{ accountId: string; accountName: string; roleId: number; userRoleId: string }];
  authUser: { name: string; email: string };
  permissions: any[];
  role: number;
  userId: string;
  favoriteApps: string[];
  roleName?: string;
  email?: any;
};
