import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';

const RolePermissionsOverview = lazy(() => import('../views/RolePermissionsOverview/RolePermissionsOverview'));

const RolesOverview = () => {
  const {
    isBackOfficeAvailable,
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  return (
    isBackOfficeAvailable && (
      <Route
        path={PAGE_PATHS.ROLES_OVERVIEW}
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path: PAGE_PATHS.ROLES_OVERVIEW, permission: Permission.READ })}>
            <RolePermissionsOverview />
          </ProtectedRoute>
        }
      />
    )
  );
};

export default RolesOverview;
