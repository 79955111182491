import React from 'react';
import { Box } from '@mui/material';
import { flex } from '../../components/AvThemeProvider';
import { downloadBlob, generateID, generateUUID, getPercent, isNullOrUndefined } from '../../utils/Utils';
import { DeliveryMethodOptions, FileFormat } from '../Reports/types';
import { hasTimeBucket } from './EditCustomDashboard/TimeBucketBreakdown/utils';
import {
  BarLegendPosition,
  BarSubType,
  BarVerticalTypeWidget,
  DonutTypeWidget,
  Field,
  LineLegendPosition,
  LineSubType,
  LineWidget,
  TableWidget,
  TextCategoryWidgetTypes,
  TextSubType,
  TileSubType,
  TileWidget,
} from './types';
import { WidgetCategory, WidgetDefinition } from './types/base.types';
import { AllTypeWidgets, CustomDashboardDto, PermissionType, ReportsTypes } from './types/combined.types';
import { PieBasicLegendPosition, PieLegendStyles, PieSubType, PieWidget } from './types/PieWidget.types';
import { dwQueryDefault } from './types/QueryObject.types';
import { TableRowSize, TableSubType } from './types/TableWidget.types';
import { SizeLimit } from './types/types';

export const widgetTempIdInit = 'temp_widget_';
export const nullValue = 'null';

export const getRepeatedValueToString = v => (Array.isArray(v) ? v.join(', ') : v);
const removeTempIdFromWidget = ({ id, ...rest }: AllTypeWidgets): AllTypeWidgets =>
  id?.startsWith(widgetTempIdInit) ? rest : { id, ...rest };

export const generateTempIdForWidget = () => `${widgetTempIdInit}${generateID()}`;
export const removeTempIdsFromDashboard = (dashboard: CustomDashboardDto): CustomDashboardDto => ({
  ...dashboard,
  widgets: dashboard.widgets.map(removeTempIdFromWidget),
});

export const filterTimeBucketFromDimensions = (dims: Field[]) => dims.filter(({ name }) => !hasTimeBucket(name));

export const shouldShowDrillDown = (newWidget: AllTypeWidgets) =>
  [WidgetCategory.Pie, WidgetCategory.Table, WidgetCategory.Bar].includes(newWidget.category);

export const canRenderWidget = ({ widget }: { widget: AllTypeWidgets }) => {
  const {
    requests: [
      {
        select: { dims, metrics },
      },
    ],
  } = widget;

  if (widget.category === WidgetCategory.Pie) {
    return (dims.length === 1 && metrics.length === 1) || (dims.length === 0 && metrics.length === 1);
  }
  if (widget.category === WidgetCategory.Table) {
    return filterTimeBucketFromDimensions(dims).length > 0 || metrics.length > 0;
  }
  if (widget.category === WidgetCategory.Bar) {
    return (dims.length === 2 && metrics.length === 1) || (dims.length === 1 && metrics.length < 6 && metrics.length > 0);
  }
  if (widget.category === WidgetCategory.Line) {
    return dims.length === 1 && filterTimeBucketFromDimensions(dims).length === 0 && metrics.length < 6 && metrics.length > 0;
  }
  if (widget.category === WidgetCategory.Tile) {
    return dims.length === 0 && metrics.length === 1;
  }
  return true;
};

export const defaultDeliveryConfig = (fileFormat = FileFormat.PDF_FORMAT) => ({
  integrationId: null,
  type: DeliveryMethodOptions.Email,
  recipients: [],
  title: '',
  message: '',
  attachmentMetadata: {
    fileFormat,
  },
});

export const defaultDashboard: CustomDashboardDto = {
  type: ReportsTypes.Visual,
  active: false,
  name: 'New Dashboard',
  intervalExpression: '0 0 * * *',
  deliveryConfig: defaultDeliveryConfig(),
  description: '',
  apps: [],
  widgets: [],
  customQueries: null,
  dashboardReportPermission: {
    viewers: [],
    editors: [],
    type: PermissionType.private,
  },
};

export const defaultDefinition: WidgetDefinition = {
  title: {
    title: '',
  },
  coords: {
    x: 0,
    y: Infinity,
    h: 4,
    w: 6,
  },
};

export const defaultDrillDownHierarchy = {
  activeIndex: 0,
  fields: [],
  drillDownValues: [],
};
export const defaultWidget: AllTypeWidgets = {
  id: '',
  definition: { ...defaultDefinition, custom: { rowSize: TableRowSize.Small } },
  category: WidgetCategory.Table,
  drillDownHierarchy: defaultDrillDownHierarchy,
  type: TableSubType.Table,
  requests: [dwQueryDefault],
};

export const tableDefaultSize = 20;
export const chartsDefaultSize = 100;
export const defaultTable = () => ({
  ...defaultWidget,
  requests: defaultWidget.requests.map(r => ({ ...r, ...(r.top ? { top: { ...r.top, size: tableDefaultSize } } : undefined) })),
});
export const defaultPie = (type: PieSubType = PieSubType.Pie): PieWidget => ({
  id: '',
  definition: {
    ...defaultDefinition,
    custom: {
      ...(type === PieSubType.Donut && { showTotals: true }),
      legend: {
        position: PieBasicLegendPosition.Top,
        style: PieLegendStyles.Table,
        showValues: true,
        showPercentage: true,
      },

      fieldsPalette: {},
    },
  },
  category: WidgetCategory.Pie,
  type,
  requests: [dwQueryDefault],
  drillDownHierarchy: defaultDrillDownHierarchy,
});

export const defaultBar: BarVerticalTypeWidget = {
  id: '',
  definition: {
    ...defaultDefinition,
    custom: { legend: { position: BarLegendPosition.Top }, rowsColumnSwitch: false, fieldsPalette: {} },
  },
  category: WidgetCategory.Bar,
  type: BarSubType.BarVertical,
  requests: [dwQueryDefault],
  drillDownHierarchy: defaultDrillDownHierarchy,
};

export const defaultLine: LineWidget = {
  id: '',
  definition: {
    ...defaultDefinition,
    custom: { fieldsPalette: {}, legend: { position: LineLegendPosition.Top } },
  },
  category: WidgetCategory.Line,
  type: LineSubType.Line,
  requests: [dwQueryDefault],
  drillDownHierarchy: defaultDrillDownHierarchy,
};

export const defaultTile: TileWidget = {
  id: '',
  definition: {
    ...defaultDefinition,
  },
  category: WidgetCategory.Tile,
  type: TileSubType.Tile,
  requests: [dwQueryDefault],
};

export const defaultText: TextCategoryWidgetTypes = {
  id: '',
  definition: { ...defaultDefinition, custom: { text: '' } },
  category: WidgetCategory.Text,
  type: TextSubType.Text,
  requests: [dwQueryDefault],
};
export enum StackedType {
  None,
  Stacked,
  Stacked100,
}
export enum OrientationType {
  Vertical,
  Horizontal,
}
export const orientationOption = {
  [BarSubType.BarHorizontal]: OrientationType.Horizontal,
  [BarSubType.StackedHorizontal]: OrientationType.Horizontal,
  [BarSubType.Percentage100_Horizontal]: OrientationType.Horizontal,
  [BarSubType.BarVertical]: OrientationType.Vertical,
  [BarSubType.StackedVertical]: OrientationType.Vertical,
  [BarSubType.Percentage100_Vertical]: OrientationType.Vertical,
};
export const stackedOption = {
  [BarSubType.BarVertical]: StackedType.None,
  [BarSubType.BarHorizontal]: StackedType.None,
  [BarSubType.StackedVertical]: StackedType.Stacked,
  [BarSubType.StackedHorizontal]: StackedType.Stacked,
  [BarSubType.Percentage100_Vertical]: StackedType.Stacked100,
  [BarSubType.Percentage100_Horizontal]: StackedType.Stacked100,
};

export const getSubType = (orientation, stacked) => {
  if (orientation === OrientationType.Horizontal) {
    if (stacked === StackedType.None) {
      return BarSubType.BarHorizontal;
    }
    if (stacked === StackedType.Stacked) {
      return BarSubType.StackedHorizontal;
    }
    return BarSubType.Percentage100_Horizontal;
  }
  if (stacked === StackedType.None) {
    return BarSubType.BarVertical;
  }
  if (stacked === StackedType.Stacked) {
    return BarSubType.StackedVertical;
  }
  return BarSubType.Percentage100_Vertical;
};

export const loadingDashboardTempLayout = [
  {
    i: 'a',
    x: 0,
    y: 0,
    w: 8,
    h: 6,
    isResizable: false,
    isDraggable: false,
  },
  {
    i: 'b',
    x: 8,
    y: 0,
    w: 4,
    h: 6,
    isResizable: false,
    isDraggable: false,
  },
  {
    i: 'c',
    x: 0,
    y: 6,
    w: 4,
    h: 4,
    isResizable: false,
    isDraggable: false,
  },
  {
    i: 'd',
    x: 4,
    y: 6,
    w: 4,
    h: 4,
    isResizable: false,
    isDraggable: false,
  },
  {
    i: 'e',
    x: 8,
    y: 6,
    w: 4,
    h: 4,
    isResizable: false,
    isDraggable: false,
  },
];

export const fieldFormatter = (value, type) => {
  if (isNullOrUndefined(value)) {
    return '';
  }
  switch (type) {
    case 'number':
      return value.toLocaleString();
    case 'percentage':
      return `${getPercent(value)}`;
    case 'date':
      return new Date(value).toLocaleString();
    case 'array':
      return value.join(', ');
    default:
      return value.toString();
  }
};

const innerLabelStyle = (label, size) => ({
  ...flex.itemsCenter,
  color: ({ palette }) => palette.colors.neutrals[600],
  position: 'relative',
  right: 6,
  mr: '-2px',
  fontSize: 12,
  ':before': {
    ...flex.center,
    content: `"${label}"`,
    backgroundColor: ({ palette }) => palette.colors.neutrals[300],
    width: 'calc(100% + 16px)',
    position: 'absolute',
    top: size === 'xSmall' ? -3 : -6,
    bottom: size === 'xSmall' ? -3 : -6,
    left: size === 'xSmall' ? -4 : -10,
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
  },
});

export const inputInnerLabelStartAdornment =
  (label, size = 'xSmall') =>
  () => <Box sx={innerLabelStyle(label, size)}>{label}</Box>;

const common: SizeLimit = { minW: 4, maxW: 12, minH: 4, maxH: 8 };

export const sizeLimitsPerCategory: Record<WidgetCategory, SizeLimit> = {
  [WidgetCategory.Pie]: common,
  [WidgetCategory.Bar]: common,
  [WidgetCategory.Line]: common,
  [WidgetCategory.Table]: common,
  [WidgetCategory.Text]: { ...common, minH: 1, minW: 2 },
  [WidgetCategory.Tile]: { ...common, minH: 1, minW: 1 },
};

export const downloadDashboardWidgetsAsJson = (widgets, dashboardName) =>
  downloadBlob(
    JSON.stringify(widgets.map(w => ({ ...w, id: generateUUID() }))),
    'application/json',
    `Avalor_${dashboardName}_dashboard_${generateID()}.json`
  );

export const activeIconStyle = theme => ({
  '&.MuiButtonBase-root.MuiIconButton-root:before': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '100%',
    boxSizing: 'content-box',
    borderRadius: '10px',
    backgroundColor: theme.palette.colors.neutrals[300],
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shorter,
    }),
  },
});

export enum ModeType {
  Edit,
  Create,
  SaveAs,
  Share,
}

export const shouldShowTotals = (widget: AllTypeWidgets) =>
  (!!(widget as DonutTypeWidget).definition.custom?.showTotals || !!(widget as TableWidget).definition.custom?.showTotals) &&
  !!widget.requests[0].select.metrics.length;
