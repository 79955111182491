import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';

const EditUserManagement = lazy(() => import('../views/UserManagement/EditUserManagement'));

const EditMyUserRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const path = PAGE_PATHS.EDIT_MY_USER;
  return (
    <Route
      path={path}
      element={
        <ProtectedRoute isAllowed={hasAllowedPermission({ path }) || hasAllowedPermission({ path, permission: Permission.UPDATE })}>
          <EditUserManagement isMyAccount showContentPermissions />
        </ProtectedRoute>
      }
    />
  );
};

export default EditMyUserRoute;
