import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import { add, sub } from 'date-fns';
import { useSnackbar } from 'notistack';
import AvSnackBarMessage from '../../components/AvSnackBarMessage.tsx';
import { getDatesFromDefinition } from '../../components/DatePicker/utils.tsx';
import { useAvContext } from '../../context/AvContextProvider.tsx';
import { NotificationContext, WorkflowType } from '../../context/AvSnackBarProvider.tsx';
import useQueryObject from '../../hooks/useQueryObjectSql.ts';
import useQuerySql from '../../hooks/useQuerySql.ts';
import { FeatureFlags, Permission, PermissionEntitiesNames, PermissionModelEntityName } from '../../types/index.ts';
import { entityViewConfig } from '../../utils/mapping.ts';
import {
  arrayToCsv,
  downloadBlob,
  getEscapedValue,
  getEscapedValueForSQLLike,
  getIfValidStringifiedObject,
  noop,
  sqlFormatter,
} from '../../utils/Utils';
import { useGetBucketAndStatuses } from '../Settings/TicketStatuses/hooks.tsx';
import { FieldType, FieldTypeEnum } from '../Sources/Mapping/mapping.types.ts';
import { apiUrls, baseEntitySqlsMap, filtersFieldToIgnore, groupByKeys, groupByKeysQueryProjection } from './ticket.types';

export const useConfirmedAndRemediatedBuckets = (entityTypeName, proj) => {
  const { buckets } = useGetBucketAndStatuses(proj);
  return useMemo(() => {
    const confirmedBucket = buckets.find(b => b.closed) || { statuses: [] };
    const remediatedBucket = buckets.find(b => b.remediated) || { statuses: [] };

    return { confirmedBucket, remediatedBucket };
  }, [entityTypeName, buckets]);
};

export const useCreateDestinationIssueForTicket = ({ entityKey, projectionId, onSuccess = () => {} }) => {
  const { api } = useAvContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setRunInfo } = useContext(NotificationContext);

  const [actionStatus, setActionStatus] = useState({});
  const createIssue = useCallback(
    (integrationId, integrationKey, dependency = Promise.resolve(), destinationName) => {
      const handleError = e => {
        const detailedError = () => (
          <AvSnackBarMessage
            title={`Create ${destinationName} Ticket Failed`}
            message={e.message}
            closeSnackbar={() => closeSnackbar(snackBarKey)}
          />
        );
        const snackBarKey = enqueueSnackbar(detailedError(), { variant: 'static', persist: true, action: <span /> });
      };
      setActionStatus({ mode: 'loading' });
      const requestUrl = integrationKey ? `${apiUrls.CREATE_SINGLE_INTEGRATION}/manual` : apiUrls.CREATE_SINGLE_INTEGRATION;
      return dependency
        .then(() =>
          api(requestUrl, {
            options: { method: 'POST' },
            onError: noop,
            isWebserver: false,
            body: { entityKey, integrationId, integrationKey, projectionId },
          })
        )
        .then(res => {
          onSuccess(res);
          if (res.attachmentWfResponse) {
            setRunInfo({
              wfId: res.attachmentWfResponse.workflowId,
              runId: res.attachmentWfResponse.runId,
              workflowType: WorkflowType.IntegrationAttachment,
            });
          }
        })
        .then(() => setActionStatus({ mode: 'success' }))
        .catch(e => {
          setActionStatus({ mode: 'error', message: e.message });
          handleError(e);
        })
        .finally(() => setTimeout(() => setActionStatus(prev => ({ ...prev, timedOut: true })), 3000));
    },
    [entityKey, onSuccess]
  );

  return { createIssue, actionStatus };
};

const mapValueFunc = v => {
  const stringValue = `${v}`;
  return `'${getEscapedValue(stringValue)}'`;
};
export const PAGE_SIZE = 20;
export const queryOrderBy = (map, filterArr) => {
  const orderByFields = map
    .filter(({ property }) => !filterArr || filterArr.find(({ id }) => id === property))
    .map(({ property, isAsc }) => `${property} ${isAsc ? 'ASC' : 'DESC'}`)
    .join(', ');
  return map.length ? `ORDER BY ${orderByFields || `${(filterArr.find(({ isKey }) => isKey) || filterArr[0]).id} ASC`}` : '';
};
const ROW_LIMIT = 40000;

const aliasRegex = /(?: as )/gi;

const getDefaultOrderBy = f => {
  const field = (f && f.split(aliasRegex)?.[1]) || f;
  return [{ property: field }];
};

export const useTicketFieldsQuery = ({
  ticketKey,
  queryKey = 'tab-table',
  where = '',
  page = 0,
  fields = [],
  fieldToExcludeFromExport = [],
  orderBy = getDefaultOrderBy(fields[0]),
  enabled,
  keyPrefix = 'finding',
  groupBy,
  parentEntityPathAlias = 'ticket',
  isCountDistinctValues,
  projectionName,
}) => {
  const {
    featureFlags,
    userPermissions: { isAvalorAdmin },
  } = useAvContext();
  const { enqueueSnackbar } = useSnackbar();
  const refetchOnWindowFocus = featureFlags[FeatureFlags.RefetchTicketOnFocus] ? 'always' : false;
  const shouldCountDistinctValues = featureFlags[FeatureFlags.CollapseHiddenTicketFlachColumns] && isCountDistinctValues;
  const fromTable = projectionName;
  const whereSQL = `${where ? `${where} AND` : 'WHERE'} ${parentEntityPathAlias}._key = ${mapValueFunc(ticketKey)}`;

  const allSQL = `select ${[...new Set([...fields, ...(groupBy ? [] : [`${keyPrefix}._key as avalor_key`])])].join(',')} 
    FROM ${fromTable} ${whereSQL} 
    ${groupBy ? `GROUP BY ${groupBy.join(', ')}` : ''} 
    ${queryOrderBy([...orderBy, ...(groupBy ? [] : [{ property: 'avalor_key', isAsc: true }])])}`;

  const exportSql = fieldToExcludeFromExport.length
    ? `select ${[...new Set([...fields.filter(f => !fieldToExcludeFromExport.includes(f)), `${keyPrefix}._key`])].join(',')} 
    FROM ${fromTable} ${whereSQL} 
    ${groupBy ? `GROUP BY ${groupBy.join(', ')}` : ''} 
    ${queryOrderBy([...orderBy, ...(groupBy ? [] : [{ property: `${keyPrefix}._key`, isAsc: true }])])}`
    : allSQL;

  const sql = `${allSQL} OFFSET ${page * PAGE_SIZE} LIMIT ${PAGE_SIZE}`;

  const onError = isAvalorAdmin
    ? e => e?.[0]?.message && enqueueSnackbar(`[Setup Error]: ${e?.[0]?.message}`, { variant: 'error' })
    : undefined;
  const { data, isLoading, isRefetching } = useQuerySql({
    key: queryKey,
    sql,
    options: { refetchOnWindowFocus, placeholderData: keepPreviousData, enabled },
    muteErrors: true,
    debounceQueries: false,
    onError,
  });
  const countSQL = `SELECT COUNT_DISTINCT(${groupBy ? `(${groupBy.join(', ')})` : `${keyPrefix}._key`}) AS count from ${fromTable} ${
    where ? `${where} AND` : 'where'
  } ${parentEntityPathAlias}._key = ${mapValueFunc(ticketKey)}`;

  const hasNonNullPrefix = 'HAS_NON_NULL_VALUES_';
  const populatedFieldsQuery =
    shouldCountDistinctValues &&
    `SELECT ${fields
      .filter(f => !f.match(aliasRegex))
      .map(
        f => `MAX(IF(${f} != null, 1, 0)) as "${hasNonNullPrefix}${f}"`
      )} from ${fromTable} WHERE ${parentEntityPathAlias}._key = ${mapValueFunc(ticketKey)}`;

  const { data: distinctValuesByFieldsMap, isLoading: isLoadingCounts } = useQuerySql({
    key: 'tab-table',
    sql: populatedFieldsQuery,
    debounceQueries: false,
    onSuccess: d =>
      Object.keys(d[0]).reduce((acc, key) => {
        if (key.includes(hasNonNullPrefix)) {
          return { ...acc, [key.replace(hasNonNullPrefix, '')]: d[0][key] };
        }
        return acc;
      }, {}),
    options: { enabled: shouldCountDistinctValues },
  });
  const { data: count } = useQuerySql({
    key: queryKey,
    sql: countSQL,
    debounceQueries: false,
  });

  return {
    data,
    isLoading,
    isLoadingCounts,
    isRefetching,
    sql: `${allSQL} LIMIT ${ROW_LIMIT}`,
    exportSql,
    count: count?.[0]?.count,
    whereSQL,
    distinctValuesByFieldsMap,
  };
};

export const AlertDestinationsListByProjectionName = (isWorkManagement, projectionName, enabled = false) => {
  const {
    api,
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  const { data, isLoading } = useQuery({
    queryKey: [`notification_targets_tickets_dropdown_${projectionName}`, enabled, isWorkManagement],
    queryFn: () =>
      api(ALERT_DESTINATIONS_LIST_BY_PROJECTION_NAME, { options: { projectionName } }).then(
        data => data?.data?.findIntegrationInstanceByAccountIdAndProjectionName
      ),

    enabled:
      enabled &&
      (projectionName !== entityViewConfig.Ticket.projectionName ||
        hasAllowedPermissionToResource({
          resource: PermissionEntitiesNames.INT,
          category: PermissionModelEntityName.TICKET,
          permission: Permission.CREATE,
        }) ||
        hasAllowedPermissionToResource({
          resource: PermissionEntitiesNames.INT,
          category: PermissionModelEntityName.TICKET,
          permission: Permission.UPDATE,
        })),
  });

  const alertDestinations = useMemo(
    () =>
      data?.filter(
        ({ category, active }) => (isWorkManagement ? category !== 'Work Management' : category === 'Work Management') && !!active
      ) || [],
    [data]
  );
  return { alertDestinations, isLoading };
};

export const transformOperator = {
  greater: 'gt',
  greater_equal: 'gte',
  less: 'lt',
  less_equal: 'lte',
};

export const getOperator = operator => transformOperator[operator] || operator;

export const predefinedSavedViewFilters = ({ buckets, pathAlias, projectionName, isArchivedStateEnabled }) => {
  const isTickets = projectionName === entityViewConfig.Ticket.projectionName;

  const now = new Date();
  const confirmedKey = 'closed';
  const notConfirmedStatuses = buckets?.filter(bucket => !bucket[confirmedKey]).flatMap(({ statuses }) => statuses.map(({ name }) => name));
  const confirmedStatuses = buckets?.filter(bucket => bucket[confirmedKey]).flatMap(({ statuses }) => statuses.map(({ name }) => name));
  return {
    active: {
      [`${pathAlias}.current_status.name`]: notConfirmedStatuses,
      ...(isTickets && { [`${pathAlias}.state`]: ['ACTIVE'] }),
    },
    openTickets: {
      [`${pathAlias}.current_status.name`]: notConfirmedStatuses,
    },
    pendingConfirmation: {
      [`${pathAlias}.current_status.name`]: notConfirmedStatuses,
      [`${pathAlias}.state`]: [isTickets && isArchivedStateEnabled ? 'INACTIVE' : 'FULLY_REMEDIATED'],
    },
    overSla: {
      [`${pathAlias}.sla`]: [
        encodeURIComponent(JSON.stringify({ value: { from: new Date('2020/01/01').toISOString(), to: now.toISOString() } })),
      ],
      [`${pathAlias}.current_status.name`]: notConfirmedStatuses,
    },
    closedTickets: {
      [`${pathAlias}.current_status.name`]: confirmedStatuses,
      ...(isTickets && { [`${pathAlias}.state`]: ['ACTIVE', isArchivedStateEnabled ? 'INACTIVE' : 'FULLY_REMEDIATED'] }),
    },
    missingAssignee: { [`${pathAlias}.assignee_id`]: [null] },
    allTickets: {},
    ticketsWithNoIntegration: {
      [`${pathAlias}.integration_info.key`]: [null],
    },
    newFindings:
      projectionName === entityViewConfig.Finding.projectionName
        ? {
            [`${pathAlias}.first_seen`]: [
              encodeURIComponent(JSON.stringify({ value: { from: sub(now, { days: 7 }).toISOString(), to: now.toISOString() } })),
            ],
          }
        : {
            [`${pathAlias}.created`]: [
              encodeURIComponent(JSON.stringify({ value: { from: sub(now, { days: 1 }).toISOString(), to: now.toISOString() } })),
            ],
            [`${pathAlias}.last_seen`]: [
              encodeURIComponent(
                JSON.stringify({ value: { from: new Date('2020/01/01').toISOString(), to: sub(now, { days: 1 }).toISOString() } })
              ),
            ],
          },
    approachingSla: {
      [`${pathAlias}.sla`]: [
        encodeURIComponent(
          JSON.stringify({
            value: {
              from: now.toISOString(),
              to: add(now, {
                days: 5,
              }).toISOString(),
            },
          })
        ),
      ],
    },
  };
};

const prefixes = {
  IP: 'IPADDRESS ',
  FIX: 'FIX ',
};

const normalizeNumberOrString = value => (typeof value === 'number' ? value : `'${value}'`);

export const useBuildWhereClause = ({ filters = {}, extra = [], entityTypeId = '', fieldMap = {}, allowAllFields = false }) => {
  const {
    featureFlags,
    accountEntities: { fieldTypeMap, fieldMap: allFieldsMap },
  } = useAvContext();

  const whereParts = [
    ...Object.keys(filters)
      .filter(field => filters[field].length > 0 && !filtersFieldToIgnore.includes(field) && (allowAllFields || !!fieldTypeMap[field]))
      .map(fieldNoQuote => {
        const fieldValue = allFieldsMap[fieldNoQuote]?.value || fieldNoQuote;
        const fieldWithQuotes = fieldValue.includes(' ') ? `"${fieldValue}"` : fieldValue;
        const field = allFieldsMap[fieldNoQuote]?.repeated ? `EXPLODE(${fieldWithQuotes})` : fieldWithQuotes;
        const objectFilter = getIfValidStringifiedObject(filters[fieldNoQuote]?.[0]);
        const fieldType = fieldMap[fieldNoQuote]?.type || fieldTypeMap[fieldNoQuote];
        if (objectFilter) {
          if (fieldType === FieldType.Date) {
            const { value, preset } = objectFilter;
            if (preset) {
              const presetDate = getDatesFromDefinition(preset, featureFlags[FeatureFlags.NewDynamicRange]);
              return `${field} >= '${presetDate.from.toISOString()}' AND ${field} <= '${presetDate.to.toISOString()}'`;
            }
            return value === null ? `${field} = null` : `${field} >= '${value.from}' AND ${field} <= '${value.to}'`;
          }
          const operator = Object.keys(objectFilter)[0];

          return `(${objectFilter[operator]
            .map(({ value, operator }) => {
              const prefix = prefixes[fieldType] || '';
              const sqlLikeEscape = getEscapedValueForSQLLike(value);
              if (operator === 'contains') {
                return `${fieldWithQuotes} LIKE '%${sqlLikeEscape}%'`;
              }
              if (operator === 'notContains') {
                return `NOT ${field} LIKE '%${sqlLikeEscape}%'`;
              }
              if (operator === 'starts') {
                return `${field} LIKE '${sqlLikeEscape}%'`;
              }
              if (operator === 'ends') {
                return `${field} LIKE '%${sqlLikeEscape}'`;
              }
              if (operator === 'equals') {
                return `${fieldWithQuotes} = ${prefix}${normalizeNumberOrString(value)}`;
              }
              if (operator === 'notEqual') {
                return `${field} != ${prefix}'${value}'`;
              }
              if (getOperator(operator) === 'gt') {
                return `${field} > ${prefix}${normalizeNumberOrString(value)}`;
              }
              if (getOperator(operator) === 'gte') {
                return `${field} >= ${prefix}${normalizeNumberOrString(value)}`;
              }
              if (getOperator(operator) === 'lt') {
                return `${field} < ${prefix}${normalizeNumberOrString(value)}`;
              }
              if (getOperator(operator) === 'lte') {
                return `${field} <= ${prefix}${normalizeNumberOrString(value)}`;
              }
              if (getOperator(operator) === 'ipRange') {
                return `${field} >= ${prefix}'${value.from}' AND ${field} <= ${prefix}'${value.to}'`;
              }
              if (getOperator(operator) === 'notIpRange') {
                return `${field} < ${prefix}'${value.from}' OR ${field} > ${prefix}'${value.to}'`;
              }
              if (getOperator(operator) === 'cidr') {
                return `SUBNET_CONTAINS('${value}', ${field})`;
              }
              if (getOperator(operator) === 'notCidr') {
                return `NOT SUBNET_CONTAINS('${value}', ${field})`;
              }
              if (operator === 'equal') {
                return `${field} = ${value}`;
              }
              if (operator === 'empty') {
                return `${field} = null`;
              }
              if (operator === 'notEmpty') {
                return `${field} != null`;
              }
              return '';
            })
            .join(` ${operator.toUpperCase()} `)})`;
        }
        if (fieldType === FieldTypeEnum[FieldType.Boolean]) {
          return `(${filters[fieldNoQuote].map(v => `${field} = ${v}`).join(' OR ')})`;
        }
        const filtersWithOutNull = filters[fieldNoQuote]
          .filter(v => v)
          .map(mapValueFunc)
          .map(v => `${prefixes[fieldType] || ''}${v}`);
        const hasNull = filters[fieldNoQuote].length > filtersWithOutNull.length;
        const result = `(${[
          filtersWithOutNull.length ? `${fieldWithQuotes} in(${filtersWithOutNull.join(', ')})` : '',
          hasNull ? `${field} = null` : '',
        ]
          .filter(d => d)
          .join(' OR ')})`;
        return featureFlags[FeatureFlags.RemoveBackslashEscapingFromWhere] ? result : result.replace(/\\/g, '\\\\').replace(/\n/g, '\\n');
      }),
  ];

  if (filters.search?.[0]) {
    whereParts.push(
      `(${entityViewConfig[entityTypeId].searchFields.map(v => `${v} LIKE '%${getEscapedValue(filters.search[0])}%'`).join(' OR ')})`
    );
  }

  if (filters.searchArray?.length && filters.searchArray[0]) {
    whereParts.push(
      `(${filters.searchArray
        .slice(1)
        .map(v => `${v} LIKE '%${getEscapedValue(filters.searchArray[0])}%'`)
        .join(' OR ')})`
    );
  }
  if (extra) {
    const extraArr = Array.isArray(extra) ? extra : [extra];
    whereParts.push(...extraArr.filter(f => f));
  }
  return whereParts.length ? `where ${whereParts.join(' AND ')}` : '';
};

export function useExportSqlToCsv(sql, headers, origin, exportData = undefined) {
  const { enqueueSnackbar } = useSnackbar();
  const [isWorking, setIsWorking] = useState();
  const exportToCSV = data => {
    const filteredHeaders = headers.filter(h => !!h.label);
    const csv = arrayToCsv([
      filteredHeaders.map(h => h.label),
      ...data.map(row =>
        filteredHeaders.reduce((acc, { id: key, getValue }) => {
          acc.push(getValue ? getValue(row) : typeof row[key] === 'object' ? JSON.stringify(row[key]) : row[key]);
          return acc;
        }, [])
      ),
    ]);
    return downloadBlob(csv, 'text/csv;charset=utf-8;', `export-${Date.now()}.csv`);
  };
  const { isLoading } = useQuerySql({
    origin,
    key: `${isWorking}-sql`,
    sql: isWorking ? sql : null,
    debounceQueries: false,
    options: { enabled: !exportData },
    onSuccess: d => {
      try {
        exportToCSV(d);
        setIsWorking();
      } catch (e) {
        enqueueSnackbar('Export failed', { variant: 'error' });
        console.error(e);
      }
    },
  });

  return { exportToCSV: () => (exportData ? exportToCSV(exportData) : setIsWorking(Date.now())), isLoading };
}

export function useExportQueryObjectToCsv(queryObject, headers, origin, globalFilters) {
  const { enqueueSnackbar } = useSnackbar();
  const [isWorking, setIsWorking] = useState(false);
  const exportToCSV = data => {
    const filteredHeaders =
      headers?.filter(h => !!h.label) ||
      Object.keys(data[0]).reduce((acc, key) => (key !== 'id' ? [...acc, { id: key, label: key }] : acc), []);
    const csv = arrayToCsv([
      filteredHeaders.map(h => h.label),
      ...data.map(row =>
        filteredHeaders.reduce((acc, { id: key, getValue }) => {
          acc.push(getValue ? getValue(row) : row[key]);
          return acc;
        }, [])
      ),
    ]);
    return downloadBlob(csv, 'text/csv;charset=utf-8;', `export-${Date.now()}.csv`);
  };

  const { data, isLoading } = useQueryObject({
    origin,
    queryObject,
    options: { enabled: isWorking },
    contextFilter: globalFilters,
    onSuccess: data => {
      try {
        exportToCSV(data);
      } catch (e) {
        enqueueSnackbar('Export failed', { variant: 'error' });
        console.error(e);
      }
      setIsWorking(false);
      return data;
    },
  });
  useEffect(() => {
    if (data && isWorking) {
      exportToCSV(data);
      setIsWorking(false);
    }
  }, [isWorking]);

  return { exportToCSV: () => setIsWorking(true), isLoading };
}

export const TicketInfoContext = createContext({});

export const buildNotificationTargetsQuery = alertDestinations =>
  alertDestinations
    ?.filter(({ matchingConditionAsSql }) => matchingConditionAsSql)
    .map(alert => `IF(${alert?.matchingConditionAsSql}, true, false) as target_${alert?.id}`)
    .join(',');

export const useUserViews = screenType => {
  const {
    api,
    userPermissions: { hasAllowedPermissionToResource },
  } = useAvContext();
  const {
    data: userViews,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['views', screenType],
    queryFn: () => api(SAVED_VIEWS, { options: { screenType } }).then(data => data?.data?.findViewsByUserIdAndAccountIdAndScreenType),
    enabled: !!screenType && hasAllowedPermissionToResource({ resource: PermissionEntitiesNames.VIEWS }),
  });
  return { userViews: userViews || [], isLoading, refetch };
};

export const getHeadCellsWithSort = (headCells, orderBy) =>
  headCells.map(h => {
    const orderByItem = orderBy.find(o => o.property === h.id);
    const sort = orderByItem ? (orderByItem.isAsc ? 'asc' : 'desc') : undefined;
    return { ...h, sort };
  });

export const useBulkIntegration = (projectionId, selectedKeys, onSuccess) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: alertDestinationId =>
      api(apiUrls.UPDATE_INTEGRATION, {
        options: { method: 'PUT' },
        isWebserver: false,
        body: { keys: selectedKeys, alertDestinationId, projectionId },
      }).then(onSuccess(alertDestinationId)),
  });
};
export const useGetFile = ({ url, fileId, isPreview, enabled = true }) => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: [`${fileId}${isPreview}`],
    queryFn: () =>
      api(`${url}&preview=${isPreview}&storedName=${fileId}`, { isWebserver: false, isJson: false }).then(async data => {
        const blob = await data.blob();
        return { dataURL: URL.createObjectURL(blob), blob };
      }),
    enabled: !!fileId && enabled,
  });
};

export const useGetRelatedTickets = ({ ticket, headCells }) => {
  const { entityType, projectionId, ticketKey, entityPathAlias } = ticket;
  const { groupingKeyField } = entityViewConfig[entityType];
  const { data: groupingKeys } = useQuerySql({
    key: 'grouping_keys',
    sql: `SELECT DISTINCT ${groupingKeyField} FROM ${projectionId.name} WHERE ${entityPathAlias}._key = '${ticketKey}'`,
    options: {
      enabled: !!groupingKeyField,
    },
    debounceQueries: false,
    onSuccess: data =>
      data
        .map(({ [groupingKeyField]: groupingKey }) => groupingKey && `'${getEscapedValue(groupingKey)}'`)
        .filter(d => d)
        .join(','),
  });
  const relatedTicketsWhereClause = groupingKeyField
    ? `WHERE ${groupingKeyField} in (${groupingKeys}) AND ${entityPathAlias}._key != '${ticketKey}'`
    : '';

  const baseSQL = baseEntitySqlsMap[entityType]({ fields: headCells, where: relatedTicketsWhereClause });
  return useQuerySql({
    key: 'related_tickets',
    sql: baseSQL,
    debounceQueries: false,
    options: {
      enabled: !!(groupingKeys?.length && groupingKeyField),
    },
  });
};

const getGroupByActionsMap = entityTypeId => ({
  [groupByKeys.assignee?.[entityTypeId]]: { sort: groupByKeys.assignee.sort },
  [groupByKeys.severity?.[entityTypeId]]: { sort: groupByKeys.severity.sort, format: groupByKeys.severity.formatter },
  [groupByKeys.sla?.[entityTypeId]]: { sort: groupByKeys.sla.sort, format: groupByKeys.sla.formatter },
  [groupByKeys.firstSeen?.[entityTypeId]]: { sort: groupByKeys.firstSeen.sort, format: groupByKeys.firstSeen.formatter },
  [groupByKeys.avalorStatus?.[entityTypeId]]: { sort: groupByKeys.avalorStatus.sort, format: groupByKeys.avalorStatus.formatter },
  [groupByKeys.created?.[entityTypeId]]: { sort: groupByKeys.created.sort, format: groupByKeys.created.formatter },
  [groupByKeys.type?.[entityTypeId]]: { sort: groupByKeys.type.sort },
});

export const useGetGroupBy = ({
  groupByKey,
  entityTypeId,
  entityName,
  projectionName,
  projections,
  where,
  onSuccess,
  displayNameMapFormatter,
  enabled = false,
}) => {
  const groupByValuesSql = sqlFormatter(
    `SELECT ${
      groupByKeysQueryProjection(entityTypeId)[groupByKey] || `${groupByKey} as val`
    }, COUNT_DISTINCT(${entityName}._key) as count from ${projectionName} ${where} GROUP BY val`
  );
  const { data, refetch, isLoading } = useQuerySql({
    key: 'group_by_values',
    sql: groupByValuesSql,
    debounceQueries: false,
    options: { enabled: !!groupByKey && enabled },
    onSuccess: groupByValues => {
      const groupByKeyParts = groupByKey?.split('.');
      const keyToRetrieve = groupByKeyParts && groupByKeyParts.length > 1 ? groupByKeyParts.slice(1).join('.') : groupByKey;
      const groupByKeyField =
        projections[projectionName].nameMap[keyToRetrieve] ||
        projections[projectionName].fieldList.INTERACTIVE.find(({ pathAlias }) => pathAlias === keyToRetrieve).title;
      const optionsMap = groupByValues
        ? groupByValues.reduce((acc, row) => {
            const keys = Array.isArray(row.val) ? row.val : [row.val];
            keys.forEach(key => {
              const rawKey = key;
              const title =
                getGroupByActionsMap(entityTypeId)?.[groupByKey]?.format?.(rawKey) ||
                displayNameMapFormatter?.[groupByKey]?.(key) ||
                rawKey ||
                `No ${groupByKeyField}`;

              acc[rawKey] = { title, value: key, count: (acc[rawKey]?.count || 0) + row.count };
            });
            return acc;
          }, {})
        : {};
      const options = Object.values(optionsMap);
      options.sort(getGroupByActionsMap(entityTypeId)?.[groupByKey]?.sort);
      onSuccess?.(options);
      return options;
    },
  });
  return { data, refetch, isLoading };
};

export const useGetShouldShowTabs = ({ tabs = [], ticketKey, projectionName, entityPathAlias }) => {
  const shouldShowTabsFieldsMap = tabs.reduce((acc, { name, shouldShowTabField }) => {
    const nameWithoutSpaces = name.replaceAll(' ', '');
    return {
      ...acc,
      ...(shouldShowTabField && { [nameWithoutSpaces]: shouldShowTabField }),
    };
  }, {});

  const shouldShowTabsSql = `SELECT ${Object.keys(shouldShowTabsFieldsMap)
    .map(tabName => `${shouldShowTabsFieldsMap[tabName]} AS ${tabName}`)
    .join(', ')} FROM ${projectionName} WHERE ${entityPathAlias}._key='${getEscapedValue(ticketKey)}'`;

  return useQuerySql({
    key: 'hidden-tabs',
    sql: shouldShowTabsSql,
    onSuccess: data => data?.[0] || {},
    options: {
      enabled: !!Object.keys(shouldShowTabsFieldsMap).length && !!ticketKey,
    },
  });
};

export const useCreateIncident = onCreateIncidentSuccess => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: alertKeys =>
      api(CREATE_INCIDENT, {
        options: { alertKeys },
      }).then(({ data }) => onCreateIncidentSuccess(data?.createManualIncident?.[0])),
  });
};

export const useSplitEntities = ({ onSuccess = d => d }) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({ name, sourceProjectionID, sourceProjectionKeys, splitStrategy = 'ALL', queryWhereClause = null, operation = 'SPLIT' }) =>
      api(SPLIT_ENTITIES, {
        options: {
          splitEntitiesRequest: {
            entityName: name,
            sourceProjectionID,
            sourceProjectionKeys,
            splitStrategy,
            queryWhereClause,
            operation,
          },
        },
        onSuccess: ({ data }) => onSuccess(data.splitEntities),
      }),
  });
};

export const useMergeEntities = ({ onSuccess = d => d }) => {
  const { api } = useAvContext();
  return useMutation({
    mutationFn: ({ title, keys, projectionId }) =>
      api(MERGE_ENTITIES, {
        options: {
          mergeEntitiesRequest: {
            keys,
            projectionID: projectionId,
            overrideFields: {
              title,
            },
          },
        },
        onSuccess: ({ data }) => onSuccess(data.mergeEntities),
      }),
  });
};

const SPLIT_ENTITIES = gql`
  mutation ($splitEntitiesRequest: SplitEntitiesRequest!) {
    splitEntities(splitEntitiesRequest: $splitEntitiesRequest) {
      wfId
      runId
    }
  }
`;

const MERGE_ENTITIES = gql`
  mutation ($mergeEntitiesRequest: MergeEntitiesRequest!) {
    mergeEntities(mergeEntitiesRequest: $mergeEntitiesRequest) {
      wfId
      runId
    }
  }
`;

const SAVED_VIEWS = gql`
  query findViewsByUserIdAndAccountIdAndScreenType($screenType: ScreenType!) {
    findViewsByUserIdAndAccountIdAndScreenType(screenType: $screenType) {
      id
      name
      screenType
      config
      publicView
      createdByUserId
      createdAt
      favorite
      defaultView
      global
      accountDefaultView
    }
  }
`;

const ALERT_DESTINATIONS_LIST_BY_PROJECTION_NAME = gql`
  query findIntegrationInstanceByAccountIdAndProjectionName($projectionName: String!) {
    findIntegrationInstanceByAccountIdAndProjectionName(projectionName: $projectionName) {
      id
      name
      active
      integrationType
      matchingConditionAsSql
      targetMatchingType
      category
      redactedConfig
      projectionName
    }
  }
`;

const CREATE_INCIDENT = gql`
  mutation createManualIncident($alertKeys: [String]!) {
    createManualIncident(alertKeys: $alertKeys) {
      wfId
      runId
    }
  }
`;
